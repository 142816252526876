import { Avatar, NavBar, PullToRefresh, Space } from "antd-mobile"
import { LogoutButton } from "./Logout"
import { MyCard, MyMorePopover, MyNavbar, MyPullToRefresh, MySpace } from "../MyDesign"
import { useMyGeneralQuery, useMyGeneralQueryAsInfiniteQuery } from "../api"
import { useNavigate } from "react-router"
import { Action } from 'antd-mobile/es/components/popover'
import { FaSistrix } from "react-icons/fa6"
import OneUIAppBar from "../OneUIAppBar"
import { UniversityUpdate, UniversityUpdatesList } from "../univeristy-updates/UniversityUpdates"
import { UserInfo, useUser } from "../contexts/UserContext"
import { useEffect } from "react"
import logger from "../logger"
import { UseQueryResult } from "react-query"




const ProfilePricture = ({userQuery} : {userQuery: UseQueryResult<UserInfo, unknown>} ) => {
    return(
        <MyCard>
            <Space direction="vertical" style={{"--gap-vertical" : '15px', width: "100%", alignItems: "center"}} >
                    <Avatar src={userQuery.isLoading ? "" : userQuery.data ? userQuery.data.picture : ""} style={{borderRadius: 50}} />
                    <div style={{fontWeight: "bold"}}>
                        {userQuery.isLoading ? "" : userQuery.data ? userQuery.data.name : "متصفح كضيف"}
                    </div>
            </Space>
        </MyCard>
    )
}

const UserUniversityUpdates = ({profilePicture} : any) => {
    const query = 
        useMyGeneralQueryAsInfiniteQuery<UniversityUpdate>('user/university-updates', "user/university-updates")

    // const universityUpdates = universityUpdatesNoPicture?.map(universityUpdate => { return {...universityUpdate, profile_picture: profilePicture}})
    return (
        <UniversityUpdatesList query={query} />
    )
}


const useActions = () => {
    const navigate = useNavigate()
    const actions: Action[] = [
        { key: 'settings', text: 'الاعدادات', onClick: () => navigate('settings')  },
    ]
    return actions
}

const ProfileScreen = () => {
    // const {data: userInfo} = 
    //     useMyGeneralQuery<UserInfo>('user/info', "user/info")
    const userQuery = useUser();



    const actions = useActions()
    return(
        // <MySpace direction="vertical" >
        <>
            <OneUIAppBar>
                <ProfilePricture userQuery={userQuery} />
            </OneUIAppBar>
            <MyNavbar  actions={actions} leftTitle={userQuery.isLoading ? "" : userQuery.data ? userQuery.data.name : "متصفح كضيف"} />
            <UserUniversityUpdates profilePicture={userQuery.isLoading ? "" : userQuery.data ? userQuery.data.picture : ""}  />
        </>
        // </MySpace>
    )
}


export {ProfileScreen}