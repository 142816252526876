import { useQuery } from "react-query";
import { BASE_URL } from "../App";
import { MyButton, MyCard, MySpace } from "../MyDesign";
import { Dialog, Divider, FloatingBubble, Space } from "antd-mobile";
import { FaPencil } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import { GithubLoginButton } from "react-social-login-buttons";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";

const AUTH_MODE = process.env.REACT_APP_AUTH_MODE as string;



const AuthLogout = () => {
    const { logout } = useAuth();

    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
        <MyButton
          disabled={AUTH_MODE === 'mock' ? true : false}
          style={{ backgroundColor: "red", color: "white", borderRadius: "14px"}}
          onClick={() => {
            Dialog.show({
              content: 'هل انت متاكد بأنك تريد تسجيل الخروج؟',
              closeOnAction: true,
              actions: [
                [
                  {
                    key: 'cancel',
                    text: 'الغاء',
                  },
                  {
                    key: 'log out',
                    text: 'تسجيل خروج',
                    bold: true,
                    danger: true,
                    onClick: () => logout()  
                  },
                ],
              ],
            })}
          }
        >
          تسجيل خروج
        </MyButton>
      </div>
    );
}


const LogoutButton = () => {
    return (
        <AuthLogout  />
    )
}

export {LogoutButton}