// src/theme.ts
type ThemeType = 'light' | 'dark'
export interface Theme {
    themeType: ThemeType,
    colors: {
        primary: string;
        secondary: string;
        tertiary: string;
        quaternary: string;
        background: string;
        surface: string;
        surfaceSecondary: string,
        surfaceTertiary: string,
        textPrimary: string;
        textSecondary: string;
        textDisabled: string;
        accent: string;
        error: string;
        success: string;
        warning: string;
    };
    shadows: {
        surfacePrimary: string,
        surfaceSecondary: string,
        surfaceTertiary: string,
    };
    fonts: {
        body: string;
        heading: string;
    };
    fontSizes: {
        small: string;
        medium: string;
        large: string;
        h1: string;
        h2: string;
        h3: string;
        h4: string;
        h5: string;
        h6: string;
    };
}

export const lightTheme: Theme = {
    themeType: 'dark',
    colors: {
        primary: '#FFD180',
        secondary: '#007991',
        tertiary: '#222E50',
        quaternary: '#F57F17',
        background: '#ffffff',
        surface: '#f8f9fa',
        surfaceSecondary: '#f8f9fa',
        surfaceTertiary: '#f8f9fa', 
        textPrimary: '#212529',
        textSecondary: '#6c757d',
        textDisabled: '#ced4da',
        accent: '#17a2b8',
        error: '#dc3545',
        success: '#28a745',
        warning: '#ffc107',
    },
    shadows: {
        surfacePrimary: "0 4px 8px rgba(0, 0, 0, 0.20)",
        surfaceSecondary: "0 4px 8px rgba(0, 0, 0, 0.20)",
        surfaceTertiary: "0 4px 8px rgba(0, 0, 0, 0.20)"
    },
    fonts: {
        body: 'Arial, sans-serif',
        heading: 'Roboto, sans-serif',
    },
    fontSizes: {
        small: '0.8rem',
        medium: '1rem',
        large: '1.2rem',
        h1: '2.5rem',
        h2: '2rem',
        h3: '1.75rem',
        h4: '1.5rem',
        h5: '1.25rem',
        h6: '1rem',
    },
};

export const darkTheme: Theme = {
    themeType: 'dark',
    colors: {
        primary: '#FFD180',
        secondary: '#007991',
        tertiary: '#222E50',
        quaternary: '#F57F17',
        background: '#010102',
        surface: '#252528',
        surfaceSecondary: '#3a3a3d',
        surfaceTertiary: '#3a3734',
        textPrimary: '#fafaff',
        textSecondary: '#b0bec5',
        textDisabled: '#999999',
        accent: '#578fff',
        error: '#d93e36',
        success: '#14a866',
        warning: '#e65b17',
    },
    shadows: {
        surfacePrimary: "0 4px 8px rgba(0, 0, 0, 0.10)",
        surfaceSecondary: "0 4px 8px rgba(0, 0, 0, 0.20)",
        surfaceTertiary: "0 4px 8px rgba(0, 0, 0, 0.30)"
    },
    fonts: {
        body: 'Arial, sans-serif',
        heading: 'Roboto, sans-serif',
    },
    fontSizes: {
        small: '0.8rem',
        medium: '1rem',
        large: '1.2rem',
        h1: '2.5rem',
        h2: '2rem',
        h3: '1.75rem',
        h4: '1.5rem',
        h5: '1.25rem',
        h6: '1rem',
    },
};
