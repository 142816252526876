import { InfiniteScroll, PullToRefresh, Skeleton } from 'antd-mobile';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MyPullToRefresh, MySpace } from '../MyDesign';
import { FetchNextPageOptions, InfiniteQueryObserverResult, UseInfiniteQueryResult } from 'react-query';
import { PaginatedResponse } from '../api';
import logger from '../logger';




const CardSkeleton = () => {
  return (
      <div style={{  display: "flex"}}>
          <Skeleton animated style={{width: "100%", minHeight: '200px', borderRadius: '14px'}}/>
      </div>
  )
}

export const CardSkeletonList = () => {
  return (
      <MySpace direction="vertical">
          { Array.from({ length: 10 }).map((val) => 
              <CardSkeleton />) }
      </MySpace>

  )
}



interface InfiniteListProps<T> {
  query: UseInfiniteQueryResult<PaginatedResponse<T>, unknown>; 
  itemRender: (item: T, index: number) => React.ReactNode;
  batchSize?: number;
}

const InfiniteList = <T,>({query, itemRender, batchSize = 20 }: InfiniteListProps<T>) => {

  const renderedItems = query.data?.pages.flatMap((page) => page.data) || [];


  const hasMore = () => {
    return query.hasNextPage ?? false;
  }
  
  

  const loadMore = async () => {
      query.fetchNextPage() 
  }
  



  return (
    query.isLoading ? 
        <CardSkeletonList /> :
        < MyPullToRefresh  onRefresh={query.refetch}>
          <MySpace direction="vertical">
            {renderedItems?.map((item, index) => (
                itemRender(item, index)
              ))}
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMore()}
            />
          </MySpace>
        </MyPullToRefresh>
      
  );
};

export default InfiniteList;
