import React, { useState, useRef } from 'react'; 
import { useNavigate } from 'react-router-dom'; 
import { useTheme } from '../contexts/ThemeContext';
import { useMyGeneralMutation, useMyGeneralQuery } from '../api';
import { Divider, Input, Mask } from 'antd-mobile';
import { MyCard } from '../MyDesign';
import { FaAngleLeft, FaEllipsisVertical, FaFilter } from 'react-icons/fa6';
import logger from '../logger';





const RecentSearchElementLabel = ({recentSearch, setMaskVisible, setSearchInput} : any) => {
    const {theme} = useTheme()
    return(
        <div style={{
            backgroundColor: theme.colors.surfaceSecondary,
            color: theme.colors.textPrimary,
            borderRadius: '12px', 
            marginRight: '7px',
            display: 'inline-block', 
            maxWidth: '100px',
            padding: '7px',
            overflow: 'hidden', textOverflow: 'ellipsis',
            boxShadow: theme.shadows.surfaceSecondary,
            fontSize: theme.fontSizes.small
            }}
            onClick={ () => { setSearchInput(recentSearch);}}
            >
            {recentSearch}
        </div>
    )
}
const RecentSearchesView = ({setMaskVisible, setSearchInput, searchHistoryEndpoint} : any) => {

    const {theme} = useTheme()
    const {data: recentSearches, refetch} = 
        useMyGeneralQuery<string[]>([searchHistoryEndpoint], searchHistoryEndpoint)

    console.log(recentSearches)

    const mutation = useMyGeneralMutation(searchHistoryEndpoint, 'delete')

    const onClearAll = async () => {
        try {
          await mutation.mutateAsync({});
          await refetch()
        } catch (error) {
          logger.error('Error during delete operation:', error);
        }
    };

    return(
        <div>
            <Divider />
            <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", 
                    fontWeight: "bold", fontSize: "10px", color: theme.colors.textSecondary, marginBottom: "10px"}}>
                <div>
                    Recent searches
                </div>
                <div style={{color: theme.colors.quaternary}} onClick={() => onClearAll()} >
                    clear all
                </div>
            </div>
            {recentSearches?.map(
                recentSearch => 
                    <RecentSearchElementLabel
                        key={recentSearch} 
                        recentSearch={recentSearch} 
                        setMaskVisible={setMaskVisible}
                        setSearchInput={setSearchInput}
                        
                        />)}      
        </div>
    )
}

const MySearchBar = ({searchText, setSearchText, searchHistoryEndpoint, setFilterVisible} : any) => {
    const navigate = useNavigate();
    const [maskVisible, setMaskVisible] = useState(false);
    const [searchInput, setSearchInput] = useState(searchText)
    const { theme } = useTheme()
    const inputRef = useRef<any>(null); // Create a ref for the input element


    const backgroundColor = maskVisible ? theme.colors.surface : theme.colors.background

    const handleMaskClick = () => {
        setMaskVisible(false);
      };

    return (
        <>
        {maskVisible && <Mask onMaskClick={handleMaskClick} destroyOnClose={true}style={{zIndex: 1, backdropFilter: 'blur(8px)'}}  />}
        <MyCard style={{ position:"sticky", left: '10px', right: '10px', top: '10px', marginBottom: '20px', zIndex: 2, maxHeight: "400px", overflow: 'auto', 
            backgroundColor: backgroundColor, boxShadow: theme.shadows.surfaceSecondary, direction: 'ltr' }}>
            <div style={{display: 'flex', flexDirection: "row", alignItems: "center", gap: '20px'}}>
                <FaAngleLeft size={20} onClick={() => navigate(-1)}/>

                <Input style={{backgroundColor: backgroundColor, width: '100%'}}
                    
                    ref={inputRef}
                    inputMode="search"
                    clearable={true}
                    placeholder="ابحث"
                    value={searchInput} 
                    onChange={val => setSearchInput(val)}                   
                    onFocus={ () => { setMaskVisible(true)} }
                    onEnterPress={ val => { setSearchText(searchInput); inputRef.current?.blur(); setMaskVisible(false)}}                
                    />
                {setFilterVisible && <FaFilter onClick={() => setFilterVisible(true) }  size={20}/>    }

                <FaEllipsisVertical size={20}/>

            </div>
            { maskVisible && <RecentSearchesView setMaskVisible={setMaskVisible} setSearchInput={setSearchInput} searchHistoryEndpoint={searchHistoryEndpoint} /> }   
        </MyCard>
        </>
            
    ) 
}



export default MySearchBar;