// src/AuthContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User, UserCredential  } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBBQUyyxP6GIoZUCWcdVKJywKMd6E_m6TA",
  authDomain: "ibtaathcafe-e5e6f.firebaseapp.com",
  projectId: "ibtaathcafe-e5e6f",
  storageBucket: "ibtaathcafe-e5e6f.appspot.com",
  messagingSenderId: "874140967210",
  appId: "1:874140967210:web:7d4de2ef4747f825a2be53"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();








interface FirebaseAuthContextType {
  currentUser: User | null;
  loginWithGoogle: () => Promise<UserCredential>;
  logout: () => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<FirebaseAuthContextType | undefined>(undefined);

export const useFirebaseAuth = (): FirebaseAuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};



export const useFirebaseAuthMock = (): FirebaseAuthContextType => {
  const mockUser: User = {
    providerId: '',
    uid: '12345',
    email: 'fakeuser@example.com',
    displayName: 'Fake User',
    photoURL: 'https://example.com/photo.jpg',
    emailVerified: true,
    isAnonymous: false,
    metadata: {
      creationTime: '2021-01-01T00:00:00Z',
      lastSignInTime: '2021-01-01T00:00:00Z',
    },
    providerData: [
      {
        providerId: 'google.com',
        uid: '12345',
        displayName: 'Fake User',
        email: 'fakeuser@example.com',
        photoURL: 'https://example.com/photo.jpg',
        phoneNumber: null,
      },
    ],
    phoneNumber: null,
    tenantId: null,
    refreshToken: 'fake-refresh-token',
    delete: async () => {
    },
    getIdToken: async (forceRefresh?: boolean) => {
      return 'fake-id-token';
    },
    getIdTokenResult: async (forceRefresh?: boolean) => {
      return {
        token: 'fake-id-token',
        expirationTime: '2022-01-01T00:00:00Z',
        authTime: '2021-01-01T00:00:00Z',
        issuedAtTime: '2021-01-01T00:00:00Z',
        signInProvider: 'google.com',
        claims: {},
        signInSecondFactor: 'false'
      };
    },
    reload: async () => {
    },
    toJSON: () => {
      return { ...mockUser };
    },
  };
    

  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const loginWithGoogle = async () => {
    // Simulate an async login process
    return new Promise<UserCredential>((resolve) => {
      setTimeout(() => {
        setCurrentUser(mockUser);
        resolve({ user: mockUser } as UserCredential);
      }, 1000); // simulate network delay
    });
  };

  const logout = async () => {
    // Simulate an async logout process
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setCurrentUser(null);
        resolve();
      }, 500); // simulate network delay
    });
  };

  return {
    currentUser,
    loginWithGoogle,
    logout,
    loading: false
  };
};



export const FirebaseAuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
        setLoading(false);

      });
      return unsubscribe;
    }, []);

    const loginWithGoogle = async () => {
      return await signInWithPopup(auth, googleProvider);
    };

    const logout = async () => {
      await signOut(auth);
    };

    const value = {
      currentUser,
      loginWithGoogle,
      logout,
      loading
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
