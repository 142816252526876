import React, { useState, useEffect, ReactElement } from 'react';

interface OneUIAppBarProps {
  title?: string;
  children?: ReactElement<any, any>;
}

const OneUIAppBar: React.FC<OneUIAppBarProps> = ({ title, children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      setIsExpanded(position < 50);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const appBarStyle: React.CSSProperties = {
    position: 'static',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 50,
    transition: 'all 300ms ease-in-out',
    height: isExpanded ? '200px' : '64px', // h-50 is not a standard Tailwind class, assuming you meant 200px
    color: 'white',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' // Equivalent to shadow-md
  };

  const containerStyle: React.CSSProperties = {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 1rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: "center",
    paddingBottom: '1rem',
  };

  const titleStyle: React.CSSProperties = {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    transition: 'all 300ms ease-in-out',
    marginBottom: isExpanded ? '0.5rem' : '0',
  };


  return (
    <div style={appBarStyle}>
      <div style={containerStyle}>
        {title && <h1 style={titleStyle}>{title}</h1> }
        {children}
      </div>
    </div>
  );
};

export default OneUIAppBar;