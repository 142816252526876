import { CascadePicker, CenterPopup, Divider, Form, TextArea } from "antd-mobile";
import { useTheme } from "../../contexts/ThemeContext";
import { MyButton, MyCard, MyForm, MyFormItem, MyInput, MyNavbar, MySpace } from "../../MyDesign";
import { GiDiploma } from "react-icons/gi";
import { useState } from "react";
import { FaRegNoteSticky } from "react-icons/fa6";
import SuccessAnimation from '../../assets/SuccesAnimation.json'
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useMyGeneralMutation } from "../../api";
import { set } from "date-fns";
import logger from "../../logger";
import SubmissionSuccessModal from "../../shared-components/SubmissionSuccessModal";

export type UniversityAdmissionServiceInterestForm = {
    phoneNumber?: string;
    sumary?: string;
}






const UniversityAdmissionServiceFormScreen = () => {
    const {theme} = useTheme();
    const [modalVisible, setModalVisible] = useState(false);

    const [form, setForm] = useState<UniversityAdmissionServiceInterestForm>();

    const mutation =  useMyGeneralMutation("/university-admission-service/interest-form", 'post')
    const navigate = useNavigate();

    const onSubmit = async () => {
        try {
            await mutation.mutateAsync(form);
            setModalVisible(true);
        } catch (error) {
        //   logger.error('Error during post operation:', error);
        }
    };
    return (
        <>
        <MyNavbar backArrow={true} leftTitle='ارفع نتيجة جامعة'  />
        <MyForm
          form={Form.useForm()[0]}
          onFinish={onSubmit}
          layout='horizontal'
          footer={
            <div style={{flexDirection: 'row', display: 'flex', gap: 10}}>
            
            <MyButton  block type='submit'  style={{backgroundColor: theme.colors.primary, color: theme.colors.background}} size='large' >
              {"تم"}
            </MyButton>
            </div>
          }
        >   
          <MySpace direction='vertical'>
            <MyCard>
                <MyFormItem name='phone number'  label={"رقم الهاتف"} rules={[{ required: true, message: 'ادخل رقم الهاتف',
                    }]}
                >
                    <MyInput 
                        placeholder={"رقم الهاتف"}
                        type="number"
                        onChange={(e) => setForm({...form, phoneNumber: e})}
                        />
                </MyFormItem>
                <Divider direction='horizontal' style={{margin: '0px'}}/>
                <MyFormItem  label={<FaRegNoteSticky /> }>
                    <TextArea
                        placeholder={"اكتب اي ملاحظة كدولة الجامعة التي تريد الفبول منها، درجة الايلتس، المعدل التراكمي، الميزانية المتوفرة، واي معلومة قد تساعدنا"} autoSize={{ minRows: 7, maxRows: 10 }} 
                        maxLength={500} showCount={true}/>
                </MyFormItem>
            </MyCard>
          </MySpace>
        </MyForm>
        <SubmissionSuccessModal visible={modalVisible} onOk={() => { setModalVisible(false); navigate("/") }}
            message="شكرا للتقديم للحصول على قبول جامعي. سيتم التواصل معك في اقرب وقت ممكن!" />
        </>
    )
}


export default UniversityAdmissionServiceFormScreen;