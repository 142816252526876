// UniversityUpdatesContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import { PaginatedResponse, useMyGeneralInfiniteQuery } from '../api';
import { UseInfiniteQueryResult } from 'react-query';
import { UniversityUpdate } from '../univeristy-updates/UniversityUpdates';



const UniversityUpdatesContext = createContext<UseInfiniteQueryResult<PaginatedResponse<UniversityUpdate>, unknown>| undefined>(undefined);

export const UniversityUpdatesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const query = useMyGeneralInfiniteQuery<UniversityUpdate, unknown>(["/university-updates"], "/university-updates");

  return (
    <UniversityUpdatesContext.Provider
      value={query}
    >
      {children}
    </UniversityUpdatesContext.Provider>
  );
};

export const useUniversityUpdates = () => {
  const context = useContext(UniversityUpdatesContext);
  if (context === undefined) {
    throw new Error('useUniversityUpdates must be used within a UniversityUpdatesProvider');
  }
  return context;
};
