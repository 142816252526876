import Lottie from 'lottie-react';
import FinancialServiceAnimation from '../../assets/FinancialServiceAnimation.json';
import { MyButton, MyForm, MyNavbar, MySpace } from '../../MyDesign';
import { Form, Skeleton } from 'antd-mobile';
import { useTheme } from '../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { UniversityAdmissionServiceInterestForm } from '../university-admission-service/UniversityAdmissionServiceForm';
import { useMyGeneralQuery } from '../../api';
import { FinancialServiceInterestForm } from './FinancialServiceForm';




const FinancialServiceScreen = () => {
    const navigate = useNavigate()

    const {data: interestForm, isLoading, refetch} = 
        useMyGeneralQuery<FinancialServiceInterestForm>('/financial-service/interest-form', '/financial-service/interest-form')

    return( 
            <>
                <MyNavbar backArrow={true} leftTitle='احصل على معونة مالية'  />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20}}>
                        <Lottie animationData={FinancialServiceAnimation} style={{maxWidth: '200px'}}/>
                        <h1 style={{textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold'}}>احصل على خدمات و معونات مالية </h1>
                        {
                        isLoading ? <Skeleton animated style={{width: "100%", minHeight: '50px', borderRadius: '2px'}}/>
                            : interestForm ? <p style={{textAlign: 'center', fontSize: '1rem', maxWidth: '300px'}}>قد تم التقديم من قبل .. سنتواصل معك قريبا</p>
                            : <p style={{ textAlign: 'center', fontSize: '1rem', maxWidth: '300px' }}>
                            حالياً نقدم الخدمات المالية التالية:
                            <ul style={{ textAlign: 'right' }}>
                              <li>المساعدة في مبلغ الإيجار الأولي</li>
                              <li>تحويل الأموال من أو إلى البنوك الأمريكية أو السعودية</li>
                              <li>قرض صغير في حالة الطوارئ</li>
                            </ul>
                            الخدمات متوفرة لعدد محدود. أظهر اهتمامك وسنتواصل معك قريبًا إذا تمت الموافقة. 
                          </p>
                        }                   
                    </div>
                    {
                        <MyButton 
                            style={{position: 'fixed',maxWidth: '300px', bottom: 100, left: "50%", transform: "translate(-50%, 0)" }}
                            onClick={() => navigate('form')}
                            disabled={isLoading || interestForm ? true : false}>
                            ابدأ الآن
                        </MyButton>
                    }
                </div>
            </>
        

    )
}


export default FinancialServiceScreen ;