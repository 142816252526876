import Lottie from 'lottie-react';
import UniversityAdmissionAnimation from '../../assets/UniversityAdmissionAnimation.json';
import { MyButton, MyForm, MyNavbar, MySpace } from '../../MyDesign';
import { Form, Skeleton } from 'antd-mobile';
import { useTheme } from '../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { UniversityAdmissionServiceInterestForm } from './UniversityAdmissionServiceForm';
import { useMyGeneralQuery } from '../../api';




const UniversityAdmissionServiceScreen = () => {
    const navigate = useNavigate()

    const {data: interestForm, isLoading, refetch, isError } = 
        useMyGeneralQuery<UniversityAdmissionServiceInterestForm>('/university-admission-service/interest-form', '/university-admission-service/interest-form')

    return( 
            <>
                <MyNavbar backArrow={true} leftTitle='احصل على قبول'  />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20}}>
                        <Lottie animationData={UniversityAdmissionAnimation} style={{maxWidth: '200px'}}/>
                        <h1 style={{textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold'}}>احصل على قبول في الجامعات العالمية</h1>
                        {
                        isLoading ? <Skeleton animated style={{width: "100%", minHeight: '50px', borderRadius: '2px'}}/>
                            : interestForm ? <p style={{textAlign: 'center', fontSize: '1rem', maxWidth: '300px'}}>قد تم التقديم من قبل .. سنتواصل معك قريبا</p>
                            : <p style={{textAlign: 'center', fontSize: '1rem', maxWidth: '300px'}}>حاليا الخدمة متوفرة لعدد محدود. ابدأ اهتمامك وسيتم التواصل معك في أقرب وقت ممكن</p>
                        }                   
                    </div>
                    {
                        <MyButton 
                            style={{position: 'fixed',maxWidth: '300px', bottom: 100, left: "50%", transform: "translate(-50%, 0)" }}
                            onClick={() => navigate('form')}
                            disabled={isLoading || interestForm ? true : false}
                            >
                            ابدأ الآن
                        </MyButton>
        }
                </div>
            </>
        

    )
}


export default UniversityAdmissionServiceScreen ;