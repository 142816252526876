import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useMyGeneralQuery } from '../api';
import { UseQueryResult } from 'react-query';

export type UserInfo = {
    userId: string,
    name: string, 
    email: string,
    picture: string,
    isAnonymous: boolean
}



const UserContext = createContext<UseQueryResult<UserInfo, unknown> | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const query = useMyGeneralQuery<UserInfo>('user/info', "user/info");
  // const [userInfoState, setUserInfo] = useState<UserInfo | null>(null);

  // useEffect(() => {
  //   if (userInfo) {
  //     setUserInfo(userInfo);
  //   }
  // }, [userInfo]);

  return (
    <UserContext.Provider value={query}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
