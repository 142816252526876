import { CascadePicker, CenterPopup, Checkbox, Divider, Form, Space, TextArea } from "antd-mobile";
import { useTheme } from "../../contexts/ThemeContext";
import { MyButton, MyCard, MyForm, MyFormItem, MyInput, MyNavbar, MySpace } from "../../MyDesign";
import { GiDiploma } from "react-icons/gi";
import { useState } from "react";
import { FaRegNoteSticky } from "react-icons/fa6";
import SuccessAnimation from '../../assets/SuccesAnimation.json'
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useMyGeneralMutation } from "../../api";
import { set } from "date-fns";
import logger from "../../logger";
import SubmissionSuccessModal from "../../shared-components/SubmissionSuccessModal";

type serviceChoice = "Rent Help" | "Money Transfer" | "Emergency Loan";
export type FinancialServiceInterestForm = {
    desiredServiceChoices: serviceChoice[];
}








const FinancialServiceFormScreen = () => {
    const {theme} = useTheme();
    const [modalVisible, setModalVisible] = useState(false);

    const [form, setForm] = useState<FinancialServiceInterestForm>({desiredServiceChoices: []});

    const mutation =  useMyGeneralMutation("/financial-service/interest-form", 'post')

    const navigate = useNavigate();

    const onDesiredServiceChoicesChange = (choice: serviceChoice) => (checked: boolean) => {
        setForm(prev => {
            const choices = checked 
                ? [...prev.desiredServiceChoices, choice] 
                : prev.desiredServiceChoices.filter(c => c !== choice);
            return { ...prev, desiredServiceChoices: choices };
        });
    }

    const onSubmit = async () => {
        try {
            await mutation.mutateAsync(form);
            setModalVisible(true);
        } catch (error) {
          logger.error('Error during post operation:', error);
        }
    };
    return (
        <>
        <MyNavbar backArrow={true} leftTitle='الخدمات المالية'  />
        <MyForm
          form={Form.useForm()[0]}
          onFinish={onSubmit}
          layout='horizontal'
          footer={
            <div style={{flexDirection: 'row', display: 'flex', gap: 10}}>
            
            <MyButton  block type='submit'  style={{backgroundColor: theme.colors.primary, color: theme.colors.background}} size='large' >
              {"تم"}
            </MyButton>
            </div>
          }
        >   
          <MySpace direction='vertical'>
            <MyCard>
                <Divider direction='horizontal' style={{margin: '0px'}}/>
                <MyFormItem name={"الخدمة المالية"} layout="vertical" label={"اختر الخدمات المالية التي تهمك" } 
                    rules={[{ required: true, message: 'اختر الخدمات المالية',
                        validator: () => { return form.desiredServiceChoices.length >= 1 ? Promise.resolve() : Promise.reject(new Error('ادخل المعدل التراكمي'))}
                      }]}
                    >
                    <Space direction='vertical' block>
                        <Checkbox onChange={onDesiredServiceChoicesChange("Rent Help")}>المساعدة في مبلغ الإيجار الأولي</Checkbox>
                        <Checkbox onChange={onDesiredServiceChoicesChange("Money Transfer")}>تحويل الأموال من أو إلى البنوك الأمريكية أو السعودية</Checkbox>
                        <Checkbox onChange={onDesiredServiceChoicesChange("Emergency Loan")}>قرض صغير في حالة الطوارئ</Checkbox>
                    </Space>
                </MyFormItem>
            </MyCard>
          </MySpace>
        </MyForm>
        <SubmissionSuccessModal visible={modalVisible} onOk={() => { setModalVisible(false); navigate("/") }}
            message="شكرا لاهتمامك في خدماتنا  المالية. سيتم التواصل معك في حين قبولك!" />

        </>
    )
}


export default FinancialServiceFormScreen;