import { MyCard, MyNavbar, MySpace } from "../MyDesign"
import { Action } from 'antd-mobile/es/components/popover'
import { Label, UniversityProfile } from "./UniversityProfile"
import { FaLink } from "react-icons/fa6"
import { PaginatedResponse, useImageQuery, useMyGeneralQuery } from "../api"
import logger from "../logger"
import { ScrollRestoration, useLocation, useNavigate } from "react-router-dom"
import { FixedSizeList, ListChildComponentProps, ListOnScrollProps } from 'react-window';
import { Divider, InfiniteScroll, Skeleton } from "antd-mobile"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { FlagIcon, FlagIconCode } from "react-flag-kit"
import { useTheme } from "../contexts/ThemeContext"
import Slider from 'react-slick';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { format } from "date-fns"
import { useUniversityProfiles } from "../contexts/UniveristyProfilesContext"
import InfiniteList from "../shared-components/InfiniteList"
import { UseInfiniteQueryResult } from "react-query"



const actions: Action[] = [
    // { key: 'filter', text: 'فلتر' },
    // { key: 'sort', text: 'نوع' },
]


const UniversityProfileCard = ({universityProfile} : {universityProfile?: UniversityProfile}) => {
    const navigate = useNavigate()
    const {theme}   = useTheme()

    const {data} = useImageQuery(`/university-profiles/public/university-logos/${universityProfile?.logoFilename}`, `/university-profiles/public/university-logos/${universityProfile?.logoFilename}`)
    const imageUrl = data ? URL.createObjectURL(data) : ''

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    const title =
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', gap: '10px'}}>
                {/* <img src={imageUrl} alt="Description of the image" width="40" height="40" style={{borderRadius: '10px'}}
                    onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}
                    /> */}
                <LazyLoadImage src={imageUrl} alt="Description of the image" width="40" height="40" style={{borderRadius: '10px'}}
                    onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}
                    />

                <div style={{fontSize: "18px"}} onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}>
                    {universityProfile?.universityNameAr}
                </div>
                <FlagIcon size={14} code={universityProfile?.countryCode as FlagIconCode} style={{borderRadius: '14px'}} />
            </div>
            <div style={{fontSize: "18px", backgroundColor: theme.colors.surfaceSecondary,  height: '30px', paddingLeft: '5px', paddingRight: '5px',  borderRadius: 8, boxShadow: theme.shadows.surfaceSecondary, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={ () => navigate(`university-profile/${universityProfile?.universityNameAr}`)}>
                {`#${Number.parseInt(universityProfile?.rank?.toString() as string)}`}
            </div>  
        </div>

    return(
        <MyCard style={{direction: 'rtl', minHeight: '150px'}}>  
            {title}   
             <Divider />
             <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
                    {universityProfile?.minimumIeltsScore && <Label labelKey={"ايلتس"} labelValue={universityProfile?.minimumIeltsScore} /> }
                    {universityProfile?.minimumToeflScore && <Label labelKey={"توفل"} labelValue={universityProfile?.minimumToeflScore} /> }
                    {universityProfile?.satRequirement && <Label labelKey={"سات"} labelValue={universityProfile?.satRequirement} /> }
                    { universityProfile?.numberOfRecommendationLetters && <Label labelKey={"عدد التوصيات"} labelValue={universityProfile?.numberOfRecommendationLetters} /> }
                    {universityProfile?.applicationFee && <Label labelKey={"الرسوم"} labelValue={universityProfile?.applicationFee} /> }
                    {universityProfile?.deadlines && <Label 
                            labelKey={"نهاية التقديم"} 
                            labelValue={
                                universityProfile?.deadlines === 'rolling admissions' 
                                    ? 'القبول المتداول' 
                                    : Array.isArray(universityProfile?.deadlines) && universityProfile.deadlines.length > 0 
                                        ? format(universityProfile.deadlines[0], 'MMMM dd, yyyy') 
                                        : 'غير محدد'
                            } 
                        /> }
                </div>
        </MyCard>
    )
}


const UniversityProfilesList = ({ query }: {query: UseInfiniteQueryResult<PaginatedResponse<UniversityProfile>, unknown>}) => {
   return (
        <InfiniteList 
            query={query}
            itemRender={(item, index) => <UniversityProfileCard key={item.universityName} universityProfile={item} /> } 
            />
   )
}






// const UniversityProfileCardSkeleton = () => {
//     return (
//         <div style={{  display: "flex"}}>
//             <Skeleton animated style={{width: "100%", minHeight: '200px', borderRadius: '14px'}}/>
//         </div>
//     )
// }

// export const UniversityProfileCardSkeletonList = () => {
//     return (
//         <MySpace direction="vertical">
//             { Array.from({ length: 10 }).map((val) => 
//                 <UniversityProfileCardSkeleton />) }
//         </MySpace>
//     )
// }




const UniversityProfilesScreen: React.FC = () => {
    const query = useUniversityProfiles();
    return (
        <div>
            <MyNavbar navigateTo={"search"} actions={actions} leftTitle={"ترتيب الجامعات"} style={{ marginBottom: '0px' }} />
            <UniversityProfilesList query={query} />    
        </div>
    );
};


export {UniversityProfilesScreen, UniversityProfilesList}