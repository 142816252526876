
import { useEffect, useRef, useState } from "react";
import { useMyGeneralMutation, useMyGeneralQuery, useMyGeneralQueryAsInfiniteQuery } from "../api";
import MySearchBar from "../shared-components/SearchBar";
import { UniversityProfile } from "./UniversityProfile";
import { UniversityProfilesList } from "./UniversityProfiles";
import logger from "../logger";
import UniversityProfileFilterModal, { UniversityProfileFilter, defaultUniversityProfileFilter } from "./UniversityProfileFilter";
import { on } from "events";




const UniversityProfilesSearchScreen = () => {
    const searchEndpoint = "/university-profiles/search"
    const [filterVisible, setFilterVisible] = useState(false);
    // const [universityProfileFilter, setUniversityProfileFilter] = useState<UniversityProfileFilter>(defaultUniversityProfileFilter);
    const [{searchText, universityProfileFilter}, setSearchAndFilter] = useState(() => {
        // Retrieve the search text from local storage on initial mount
        const storageVal = localStorage.getItem(searchEndpoint);
        return storageVal ? JSON.parse(storageVal) as {searchText: string, universityProfileFilter: UniversityProfileFilter} : {searchText: "", universityProfileFilter: defaultUniversityProfileFilter};
    });
    useEffect(() => {
        localStorage.setItem(searchEndpoint, JSON.stringify({searchText, universityProfileFilter}));
    }, [searchText, universityProfileFilter]);

    const query = 
        useMyGeneralQueryAsInfiniteQuery<UniversityProfile>([searchEndpoint, searchText, JSON.stringify(universityProfileFilter)], searchEndpoint, { search: searchText, filter: JSON.stringify(universityProfileFilter) },
        {
            enabled: searchText !== "", // Only enable the query if searchText is not an empty string
        })

    const setSearchText = (text: string) => {
        setSearchAndFilter( (prev: any) => ({...prev, searchText: text}));
    }
    const setUniversityProfileFilter = (filter: UniversityProfileFilter) => {
        setSearchAndFilter( (prev: any) => ({...prev, universityProfileFilter: filter}));
    }

    return( 
        <>  
            <MySearchBar searchText={searchText} setSearchText={setSearchText} searchHistoryEndpoint={"/university-profiles/search-history"} setFilterVisible={setFilterVisible} />
            <UniversityProfileFilterModal visible={filterVisible} setVisible={setFilterVisible} UniversityProfileFilter={universityProfileFilter} setUniversityProfileFilter={setUniversityProfileFilter}  />
            <UniversityProfilesList query={query} /> 
        </>
    )
}

export default UniversityProfilesSearchScreen