// src/ThemeProvider.tsx
import React, { ReactNode, createContext, useContext, useState } from 'react';
import { Theme, darkTheme } from '../theme';



interface ThemeContextProps {
    theme: Theme;
    setTheme: React.Dispatch<React.SetStateAction<Theme>>; // Function to toggle theme
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider = ({defaultTheme=darkTheme, children }: {defaultTheme?:Theme, children?: ReactNode }) : JSX.Element => {
    const [theme, setTheme] = useState(defaultTheme);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};


