import { UseInfiniteQueryResult, useQuery } from "react-query";
import { BASE_URL } from "../App";
import { MyCard, MyFloatingBubble, MyMorePopover, MyNavbar, MyPullToRefresh, MySpace } from "../MyDesign";
import { Divider, FloatingBubble, NavBar, Popover, Space, Skeleton, Avatar, InfiniteScroll, Image, PullToRefresh, Toast } from "antd-mobile";
import { FaEllipsisVertical, FaPenToSquare, FaPencil, FaSistrix } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import { PaginatedResponse, useImageQuery, useMyGeneralInfiniteQuery, useMyGeneralQuery } from "../api";
import { AdComponent } from "../AdComponent";
import { Action } from 'antd-mobile/es/components/popover'

import { useUser } from "../contexts/UserContext";
import { useUniversityUpdates } from "../contexts/UniversityUpdatesContext";
import { useTheme } from "../contexts/ThemeContext";
import { Theme } from "../theme";
import logger from "../logger";
import { UniversityProfile } from "../university-profile/UniversityProfile";
import { useState } from "react";
import InfiniteList from "../shared-components/InfiniteList";
import Lottie from "lottie-react";
import FiveCirclesLoadingAnimation from "../assets/FiveCirclesLoadingAnimation.json"
var randomColor = require('randomcolor'); // import the script


export type UniversityUpdate = {
    id: string;
    user_id?: string;
    profile_picture?: string;
    university: string;
    general_degree: string;
    specific_degree: string;
    program: string;
    admission_year: string;
    admission_season: string;
    status: string;
    update_note: string;
    gpa_scale: string;
    gpa: string;
    gre_quant?: string;
    gre_verbal?: string;
    gre_writing?: string;
    ielts?: string;
    createdAt: string;
    updatedAt: string;
};




const Label = ({labelKey, labelValue } : any) => {
    const {theme} = useTheme()
    return(
        <div style={{maxWidth: '70px', minWidth: '50px', width: "20%", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{color: theme.colors.textSecondary, fontSize: '10px', marginBottom: '3px'}}>
                {labelKey}
            </div>
            <div style={{width: "100%", height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                backgroundColor: theme.colors.primary, borderRadius: '15px', color: theme.colors.background,    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.20)',            }}>
                {labelValue}
            </div>
        </div>
    )
}

const getStatusColor = (status: string, theme: Theme) => { 
    
    const statusColors: { [key: string]: string } = {
        "تحت المراجعة": theme.colors.textDisabled,
        "مقبول": theme.colors.success,
        "مسحوب": theme.colors.warning,
        "مرفوض": theme.colors.error
    };
    return ( statusColors[status] || theme.colors.textDisabled); // Default to 'gray' if status not found)

}
const gpaScaleMapping: { [key: string]: string } = {
    'مقياس 4.0 (الولايات المتحدة)': '4',
    'مقياس 5 نقاط (المملكة العربية السعودية)': '5',
    'مقياس 100 نقطة (دولي)': '100',
    'مقياس النسبة المئوية (الهند وبعض الدول الأخرى)': '100', // Percentage
    'مقياس 20 نقطة (المملكة المتحدة)': '20',
    'مقياس 7 نقاط (أستراليا)': '7',
    'مقياس 10 نقاط (كندا)': '10',
  };

const getGpaNumber = (gpaScale: string): string  => {
    return gpaScaleMapping[gpaScale];
};
  

const UniversityUpdateCard = ({universityUpdate}: {universityUpdate: UniversityUpdate} ) => {
    const {theme} = useTheme()
    const navigate = useNavigate()
    const userQuery = useUser();
    const gpaValue = `${universityUpdate.gpa}/${getGpaNumber(universityUpdate.gpa_scale)}`
    const universityNameAr = universityUpdate.university
    const {data: universityProfile} = useMyGeneralQuery<UniversityProfile>([`/university-profiles/univeristy-profile/${universityNameAr}`], `/university-profiles/university-profile/${universityNameAr}`)

    const {data} = useImageQuery(`/university-profiles/public/university-logos/${universityProfile?.logoFilename}`, `/university-profiles/public/university-logos/${universityProfile?.logoFilename}`)
    const imageUrl = data ? URL.createObjectURL(data) : ''

    return(
        <MyCard style={{width: "100%", minHeight: '150px', paddingLeft: "20px", paddingRight: "20px"}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', gap: '5px'}}>
                <img src={imageUrl} alt="university logo" width="40" height="40" style={{borderRadius: '10px'}}
                    onClick={ () => navigate(`university-profile/${universityUpdate.university}`)}
                    />

                <div style={{fontSize: "18px"}} onClick={ () => navigate(`university-profile/${universityUpdate.university}`)}>
                    {universityUpdate.university} ، {universityUpdate.program}
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px'}}>
                    <div style={{fontSize: '10px'}}>
                        {formatDistance(Date(),universityUpdate.updatedAt)}
                    </div>
                    <div style={{borderRadius: '20px', width: "60px", height: '30px',backgroundColor: getStatusColor(universityUpdate.status, theme), 
                        fontSize: "10px", display: "flex", direction: 'ltr', justifyContent: "center", alignItems: "center",
                        boxShadow: theme.shadows.surfacePrimary
                        }}>
                    {universityUpdate.status} 
                    </div>
                </div>
            </div>
            <Divider direction="horizontal" style={{borderColor: 'transparent', marginTop: '7.5px', marginBottom: '7.5px'}} />

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'center', gap: '5px' }}>
                <Label labelKey={"الدرجة"} labelValue={universityUpdate.general_degree} />
                <Label labelKey={"الفصل"} labelValue={universityUpdate.admission_season} />
                <Label labelKey={"المعدل"} labelValue={gpaValue} />
                { universityUpdate.gre_quant && <Label labelKey={"GRE"} labelValue={universityUpdate.gre_quant} /> }
                { universityUpdate.ielts && <Label labelKey={"IELTS"} labelValue={universityUpdate.ielts} /> }
            </div>
            { universityUpdate.update_note &&
                <>
                <Divider direction="horizontal" />

                <div style={{}}>
                    {universityUpdate.update_note}
                </div>
                </> }
            <MySpace />
            {universityUpdate.user_id == userQuery.data?.userId && 
                <>                       
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <FaPenToSquare color="white" onClick={() => navigate(`/university-update/submission/${universityUpdate.id}`)}/>
                </div>
                </> }
        </MyCard>
        
    )
}





const UniveristyUpdateCardWithAvatar = ({universityUpdate} : {universityUpdate: UniversityUpdate}) => {
    return (
        <div style={{ flexDirection: 'row', display: "flex"}}>
            <Avatar src={universityUpdate.profile_picture ?? ""} style={{borderRadius: 50, marginLeft: '10px'}} />
            <UniversityUpdateCard universityUpdate={universityUpdate} />
        </div>
    )
}


const UniversityUpdateCardSkeleton = () => {
    return (
        <div style={{ flexDirection: 'row', display: "flex"}}>
            <Skeleton animated style={{width: '50px', height: '50px', borderRadius: '50%',  marginLeft: '10px'}} />
            <Skeleton animated style={{width: "100%", minHeight: '200px', borderRadius: '14px'}}/>
        </div>
    )
}



export const UniversityUpdatesList = ({ query} : {query: UseInfiniteQueryResult<PaginatedResponse<UniversityUpdate>, unknown>}) => {
    return (
         <InfiniteList 
            query={query}   
            itemRender={(item, index) => <UniveristyUpdateCardWithAvatar key={item.id} universityUpdate={item} /> }
            />
    )
}


const UniversityUpdateCreationBubble = () => {
    const navigate = useNavigate()
    return(
        <MyFloatingBubble
            onClick={ () => navigate('submission')}
        >
            <FaPencil color={"white"} />
      </MyFloatingBubble>
    )
}


const actions: Action[] = [
    // { key: 'filter', text: 'فلتر' },
    // { key: 'sort', text: 'نوع' },
]


const UniversityUpdatesScreen = () => {    
    const query = useUniversityUpdates();

    return ( 
        <div style={{}}>
            <MyNavbar navigateTo={"search"} actions={actions} leftTitle={'تحديثات القبول'} />
            <UniversityUpdatesList query={query} />
            <UniversityUpdateCreationBubble />
        </div>
    ) 
}

export {UniversityUpdatesScreen}