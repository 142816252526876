import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      // "Submit": "Submit",
      // "University": "University",
      // "Program": "Program",
      // "Degree": "Degree",
      // "Admission Season": "Admission Season",

      // "Status": "Status",
      // "In Procress": "In Progress",
      // "Admitted": "Admitted",
      // "Rejected": "Rejected",
      // "Withdrawn": "Withdrawn"

    }
  },
  ar: {
    translation: {
      "Submit": "تم",
      "University": "الجامعة",
      "Program": "البرنامج",
      "Degree": "الدرجة العلمية",
      "Admission Season": "عام القبول",

      "Status": "الحالة",
      "In Process": "تحت المراجعة",
      "Admitted": "مقبول",
      "Rejected": "مرفوض",
      "Withdrawn": "مسحوب",

      "Notification Date": "تاريخ الاشعار",
      "Write any note about the admission. Be Precise.": "أكتب أي ملاحطة عن عملية التقديم على القبول. كن مختصرا.",
      "GPA Scale": "ميزان المعدل التراكمي",
      "GPA": "المعدل التراكمي",

      "Quant": "كمي",
      "Verbal": "لفظي",
      "Writing": "كتابي",

      "IELTS Score": "درجة الايلتس"

    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ar", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    returnEmptyString: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;