import { useEffect, useState } from "react";
import { useTheme } from "./contexts/ThemeContext";
import { CenterPopup } from "antd-mobile";
import logger from "./logger";

const WelcomeModal = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisited') === 'true';

        logger.debug('hasVisited:', hasVisited);

        if (!hasVisited) {
            // If not, show the modal and set 'hasVisited' in localStorage
            setVisible(true);
            localStorage.setItem('hasVisited', 'true');
        }
    }, []);

    const { theme } = useTheme();
    const styles = {
        heading: {
            margin: '20px 0', 
        },
        paragraph: {
            marginBottom: '20px',
        },
        list: {
            paddingLeft: '20px', 
            marginBottom: '20px', 
        },
        listItem: {
            marginBottom: '10px', 
        },
        email: {
            color: 'blue', 
        }
    };

    return (
        <CenterPopup
            visible={visible}
            onMaskClick={() => {
                setVisible(false);
            }}
            onClose={() => {
                setVisible(false);
            }}
            bodyStyle={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                fontSize: theme.fontSizes.small, backgroundColor: theme.colors.surface
            }}
            maskStyle={{ backdropFilter: 'blur(8px)' }}
        >
            <div style={{ padding: '20px', lineHeight: '1.8', height: '70vh', overflowY: 'scroll', direction: 'rtl', textAlign: 'right' }}>
                <h1 style={styles.heading}>مرحباً بك في مقهى الابتعاث!</h1>
                <p style={styles.paragraph}>
                    نحن سعداء بانضمامك إلى مجتمعنا. في مقهى الابتعاث، يمكنك القيام بالعديد من الأنشطة التي تساعدك على تحقيق أهدافك الدراسية. يمكنك:
                </p>
                <ul style={styles.list}>
                    <li style={styles.listItem}>عرض، بحث، وتصفية الجامعات، ومعرفة ترتيبها، ومتطلبات القبول مثل درجة IELTS.</li>
                    <li style={styles.listItem}>عرض، بحث، وتصفية تجارب القبول الخاصة بك وتلك الخاصة بالآخرين، بما في ذلك درجاتهم، وما إذا تم قبولهم أم لا. يمكنك أيضًا مشاركة تجربتك الخاصة سواءً بشكل مجهول (وهو الوضع الافتراضي) أو باستخدام معرف المستخدم الخاص بك.</li>
                    <li style={styles.listItem}>الاستفادة من خدمات مثل القبولات، والقروض التي تساعد في نفقات الإيجار، وتحويل الأموال.</li>
                </ul>
                <p style={styles.paragraph}>
                    نحن هنا لدعمك في رحلتك الأكاديمية، ونتطلع إلى مساعدتك في تحقيق أهدافك!
                </p>
            </div>
        </CenterPopup>
    );
};

export default WelcomeModal;
