import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { css } from '@emotion/react';
import {
  BrowserRouter, RouterProvider
} from "react-router-dom";
import { UniversityUpdatesScreen } from './univeristy-updates/UniversityUpdates';
import './i18';
import { AuthProvider } from './contexts/AuthContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  
);

const setGlobalStyles = () => {
  document.body.style.margin = '0';
  document.body.style.padding = '0';
  document.documentElement.style.margin = '0';
  document.documentElement.style.padding = '0';
  document.body.style.width = '100%';
  document.body.style.height = '100%';
  document.documentElement.style.width = '100%';
  document.documentElement.style.height = '100%';

  document.body.style.overscrollBehaviorY = 'contain'; // Prevent pull-to-refresh

  
};

setGlobalStyles();



document.documentElement.setAttribute(
  'data-prefers-color-scheme',
  'dark'
)

root.render(
  <React.StrictMode  >
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
