// axiosInstance.ts
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions, QueryKey, UseMutationOptions, UseMutationResult, MutationFunction, useMutation,
  useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useAuth } from './contexts/AuthContext';
import { useRef, useState } from 'react';




const useAxios = () => {
  // const { getAccessTokenSilently } = useMyAuth0();
  const {currentUser} = useAuth()
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    if(currentUser){
      const token = await currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  return axiosInstance;
};



const useMyGeneralQuery = <TData = unknown, TError = unknown>(
  key: QueryKey,
  endpoint: string,
  params?: Record<string, any>,
  options?: UseQueryOptions<TData, TError>
) => {
  const axios = useAxios();

  return useQuery<TData, TError>(
    key,
    async () => {
      const { data } = await axios.get(endpoint, { params });
      return data;
    },
    options
  );
};



export interface PaginatedResponse<T> {
  data: T[];        // Adjust 'data' to match the property your API returns for items
  hasNextPage: boolean
}


export const useMyGeneralInfiniteQuery = <TData = unknown, TError = unknown>(
  key: QueryKey,
  endpoint: string,
  initialParams?: Record<string, any>,
  options?: UseInfiniteQueryOptions<PaginatedResponse<TData>, TError>
) => {
  const axios = useAxios();

  return useInfiniteQuery<PaginatedResponse<TData>, TError>(
    key,
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(endpoint, {
        params: { ...initialParams, page: pageParam, limit: 20 }, // Adjust limit as needed
      });
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // Ensure nextPage is based on the current page, and totalPages from lastPage
        if (lastPage.hasNextPage) {
          return pages.length + 1;
        }
        return undefined;

      },
      ...options,
    }
  );
};



// Assuming you have a custom hook for Axios

export const useMyGeneralQueryAsInfiniteQuery = <TData = unknown, TError = unknown>(
  key: QueryKey,
  endpoint: string,
  initialParams: Record<string, any> = {},
  options?: UseInfiniteQueryOptions<PaginatedResponse<TData>, TError>
) => {
  const axios = useAxios();

  // Use a ref to store the full dataset persistently
  const fullDataRef = useRef<TData[]>([]);

  return useInfiniteQuery<PaginatedResponse<TData>, TError>(
    key,
    async ({ pageParam = 1 }) => {
      const limit = 20; // Define the limit (items per page)

      // Fetch the full data only at the first page (at the beginning)
      if (pageParam === 1) {
        const { data: fetchedData } = await axios.get(endpoint, { params: { ...initialParams } });
        fullDataRef.current = fetchedData; // Store the fetched data in the ref
      }

      // Calculate start and end indices based on the current pageParam
      const start = (pageParam - 1) * limit;
      const end = start + limit;

      // Get the current page's data from the fetched fullData in the ref
      const paginatedData = fullDataRef.current.slice(start, end);

      // Determine if there is a next page
      const hasNextPage = end < fullDataRef.current.length;

      return {
        data: paginatedData as TData[],
        hasNextPage,
      };
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // If there's a next page, return the next page number; otherwise, return undefined
        return lastPage.hasNextPage ? pages.length + 1 : undefined;
      },
      ...options,
    }
  );
};







// export const useMyGeneralQueryAsInfiniteQuery = <TData = unknown, TError = unknown>(
//   key: QueryKey,
//   endpoint: string,
//   initialParams: Record<string, any> = {},
//   options?: UseInfiniteQueryOptions<PaginatedResponse<TData>, TError>
// ) => {
//   const axios = useAxios();

//   return useInfiniteQuery<PaginatedResponse<TData>, TError>(
//     key,
//     async ({ pageParam = 1 }) => {
//       const limit = 20;  // Define the limit (items per page)
      
//       // Fetch data for the current page using the params (simulate pagination)
//       const { data: fullData } = await axios.get(endpoint, { params: { ...initialParams } });

//       // Slice the data to simulate pagination (fetching one page at a time)
//       const start = (pageParam - 1) * limit;
//       const paginatedData = fullData.slice(start, start + limit); // Get the current page data

//       // Determine if there is a next page
//       const hasNextPage = start + limit < fullData.length;

//       return {
//         data: paginatedData as TData[],
//         hasNextPage,
//       };
//     },
//     {
//       getNextPageParam: (lastPage, pages) => {
//         // If there's a next page, return the next page number; otherwise, return undefined
//         return lastPage.hasNextPage ? pages.length + 1 : undefined;
//       },
//       ...options,
//     }
//   );
// };


export const useMyLocalInfiniteQuery = <TData = unknown, TError = unknown>(
  key: QueryKey,
  dataArray: TData[],
  options?: UseInfiniteQueryOptions<PaginatedResponse<TData>, TError>,
  itemsPerPage: number = 20 // Optional: Define how many items per page
) => {
  return useInfiniteQuery<PaginatedResponse<TData>, TError>(
    key,
    async ({ pageParam = 1 }) => {
      // Simulate the paginated response from the local data array
      const startIndex = (pageParam - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      const paginatedItems = dataArray.slice(startIndex, endIndex);
      const totalPages = Math.ceil(dataArray.length / itemsPerPage);

      // Adjust the return type to match PaginatedResponse<TData>
      return {
        data: paginatedItems, // Must be 'data', not 'items'
        hasNextPage: pageParam < totalPages,
      };
    },
    {
      getNextPageParam: (lastPage, pages) => {
        // Check if there are more pages available
        if (lastPage.hasNextPage) {
          return pages.length + 1;
        }
        return undefined;
      },
      ...options,
    }
  );
};



export const useImageQuery = (key: string, endpoint: string, params?: Record<string, any>, options?: UseQueryOptions<Blob>) => {
  const axios = useAxios();

  return useQuery<Blob>(
    key,
    async () => {
      const response = await axios.get(endpoint, {
        params,
        responseType: 'blob' // Ensure response is treated as a Blob
      });
      return response.data;
    },
    options
  );
};




type MutationFn<TData, TVariables> = (variables: TVariables) => Promise<AxiosResponse<TData>>;


const useMyGeneralMutationDeprecated = <TData = unknown, TError = AxiosError, TVariables = any>(
  endpoint: string,
  options?: UseMutationOptions<AxiosResponse<TData>, TError, TVariables>
): UseMutationResult<AxiosResponse<TData>, TError, TVariables> => {
  const axios = useAxios();

  const mutationFn: MutationFn<TData, TVariables> = async (variables: TVariables) => {
    const { data } = await axios.post(endpoint, variables);
    return data;
  };

  return useMutation<AxiosResponse<TData>, TError, TVariables>(mutationFn, options);
};


type HttpMethod = 'post' | 'put' | 'delete';

const useMyGeneralMutation = <TData = unknown, TError = AxiosError, TVariables = any>(
  endpoint: string,
  method: HttpMethod = 'post',
  options?: UseMutationOptions<AxiosResponse<TData>, TError, TVariables>
): UseMutationResult<AxiosResponse<TData>, TError, TVariables> => {
  const axios = useAxios();

  const mutationFn: MutationFunction<AxiosResponse<TData>, TVariables> = async (variables: TVariables) => {
    let response;
    switch (method) {
      case 'post':
        response = await axios.post(endpoint, variables);
        break;
      case 'put':
        response = await axios.put(endpoint, variables);
        break;
      case 'delete':
        response = await axios.delete(endpoint, { data: variables });
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }
    return response.data;
  };

  return useMutation<AxiosResponse<TData>, TError, TVariables>(mutationFn, options);
};








export { useMyGeneralQuery, useMyGeneralMutation };
