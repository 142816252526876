import { CalendarPicker, CenterPopup, CheckList, Divider, Picker, Popup, SearchBar, Selector, Slider, Toast } from "antd-mobile";
import React, { createContext, useState } from "react";
import { MyButton, MyDivider, MyForm, MyFormItem, MyInput, MySelector } from "../MyDesign";

import {Country, countries, currencies} from 'country-data'
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { FaAngleLeft } from "react-icons/fa6";
import Fuse from 'fuse.js';
import logger from "../logger";
import { useTheme } from "../contexts/ThemeContext";
import { log } from "console";
import { set } from "date-fns";


export type UniversityProfileFilter = {
    countryCode: string[],
    rank: [number, number],

    minimumIeltsScore: [number, number],
    minimumToeflScore: [number, number],
    satRequirement: string[],

    numberOfRecommendationLetters: [number, number],
    deadline: [Date, Date],
    applicationFee: [number, number],
}

export const defaultUniversityProfileFilter: UniversityProfileFilter = {
    countryCode: countries.all.map(country => country.alpha2),
    rank: [0, 1000],

    minimumIeltsScore: [0, 9],
    minimumToeflScore: [0, 120],
    satRequirement: ['optional', 'not required', 'considered but not required', 'required', 'neither required nor recommended', 'recommended'],

    numberOfRecommendationLetters: [0, 3],
    deadline: [new Date(), new Date(2025, 12, 31)],
    applicationFee: [0, 200]

}


const ListWithSearch: React.FC<any> = ({ visible, setVisible, data, extractItem, selected, onSelect }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    // Set up Fuse.js options
    const fuseOptions = {
        keys: ['name'], // Adjust this key to match the property in your data items you want to search on
    };
    

    const fuse = new Fuse(data, fuseOptions);

    const handleSearch = (query: string) => {
        setSearchQuery(query);
        query ? 
            setFilteredData(fuse.search(query).map(result => result.item))
            : setFilteredData(data);
    };

    return (
        <Popup
            bodyStyle={{ height: '60vh', overflow: 'scroll', borderTopLeftRadius: '24px', borderTopRightRadius: '24px'}}
            visible={visible}
            onMaskClick={() => {
                setVisible(false);
            }}
            destroyOnClose={true}
        >
            <div>
                <SearchBar 
                    placeholder='search'
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <MyButton size="small" onClick={() => onSelect([])}> Uncheck all </MyButton>
                <MyButton size="small" onClick={() => onSelect(countries.all)}> check all </MyButton>

            </div>
            <div>
                <CheckList
                
                    multiple={true}
                    value={selected}
                    onChange={val => {
                        onSelect(val);
                    }}
                >

                    {filteredData.map((item: any) => {
                        // logger.debug(item);
                        return (
                            <CheckList.Item  value={item}>
                                {extractItem(item)}
                            </CheckList.Item>
                        )})}
                </CheckList>
            </div>
        </Popup>
    );
};

const satRequirementOptions = [
    { value: 'optional', label: 'اختياري' },
    { value: 'not required', label: 'غير مطلوب' },
    { value: 'considered but not required', label: 'مُعتبر ولكن غير مطلوب' },
    { value: 'required', label: 'مطلوب' },
    { value: 'neither required nor recommended', label: 'لا مطلوب ولا موصى به' },
    { value: 'recommended', label: 'موصى به' }
];


const UniversityProfileFilterModal = ({visible, setVisible, UniversityProfileFilter, setUniversityProfileFilter} : any) => {

    const [temporaryFilter, setTemporaryFilter] = useState(UniversityProfileFilter)

    const [countryListVisible, setCountryListVisible] = useState(false);
    const [deadlineCalendarVisible, setDeadlineCalendarVisible] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState<Country[]>(countries.all as Country[]);

    const { theme } = useTheme();
    const ieltsMarks = {
        0: 0,
        0.5: "",
        1: 1,
        1.5: "",
        2: 2,
        2.5: "",
        3: 3,
        3.5: "",
        4: 4,
        4.5: "",
        5: 5,
        5.5: "",
        6: 6,
        6.5: "",
        7: 7,
        7.5: "",
        8: 8,
        8.5: "",
        9: 9,
    };
    const lorMarks = {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
    };

    const onSelectCountries = (countries: Country[]) => {
        setSelectedCountries(countries);
        setTemporaryFilter({
            ...temporaryFilter,
            countryCode: countries.map((country) => country.alpha2),
        });
    };

    return (
        <CenterPopup
            visible={visible}
            disableBodyScroll
            onMaskClick={() => {
                setVisible(false);
                setUniversityProfileFilter(temporaryFilter);
            }}
            maskStyle={{ backdropFilter: "blur(8px)" }}
            bodyStyle={{
                paddingTop: "10px",
                paddingBottom: "10px",
                height: "70vh",
                borderRadius: "24px",
                overflow: "scroll",
                boxShadow: theme.shadows.surfaceSecondary,
                backgroundColor: theme.colors.surface,
                scrollbarWidth: "none",
            }}
        >
            <MyForm
                footer={
                    <div style={{ flexDirection: "row", display: "flex", gap: 10 }}>
                        <MyButton
                            block
                            type="button"
                            onClick={() => {
                                setTemporaryFilter(defaultUniversityProfileFilter);
                                setSelectedCountries([]);
                            }}
                            style={{
                                backgroundColor: theme.colors.primary,
                                color: theme.colors.background,
                            }}
                            size="large"
                        >
                            {"اعادة تعيين"}
                        </MyButton>
                    </div>
                }
            >
                <MyFormItem
                    label={"دولة الجامعة"}
                    onClick={() => setCountryListVisible(true)}
                >
                    <div
                        style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                    >
                        {selectedCountries == countries.all ? (
                            <div> جميع الدول</div>
                        ) : (
                            selectedCountries.map((selectedCountry) => (
                                <div>
                                    {selectedCountry.name + " " + selectedCountry.emoji}
                                </div>
                            ))
                        )}
                    </div>
                    <ListWithSearch
                        visible={countryListVisible}
                        setVisible={setCountryListVisible}
                        data={countries.all}
                        extractItem={(item: Country) => item.name}
                        selected={selectedCountries}
                        onSelect={onSelectCountries}
                    />
                </MyFormItem>
                <MyFormItem label={" ترتيب الجامعة"}>
                    <Slider
                        value={temporaryFilter.rank}
                        range={true}
                        min={0}
                        step={10}
                        max={1000}
                        popover={true}
                        onChange={(value) =>
                            setTemporaryFilter({ ...temporaryFilter, rank: value as [number, number] })
                        }
                    />
                </MyFormItem>
                <MyDivider />
                <MyFormItem label={" درجة الايلتس"}>
                    <Slider
                        value={temporaryFilter.minimumIeltsScore}
                        range={true}
                        marks={ieltsMarks}
                        min={0}
                        max={9}
                        onChange={(value) =>
                            setTemporaryFilter({ ...temporaryFilter, minimumIeltsScore: value as [number, number] })
                        }
                    />
                </MyFormItem>
                <MyFormItem label={" درجة التوفل"}>
                    <Slider
                        value={temporaryFilter.minimumToeflScore}
                        range={true}
                        popover={true}
                        min={0}
                        max={120}
                        onChange={(value) =>
                            setTemporaryFilter({ ...temporaryFilter, minimumToeflScore: value as [number, number] })
                        }
                    />
                </MyFormItem>
                <MyFormItem label={"اختبار السات"}>
                    <MySelector
                        value={temporaryFilter.satRequirement}
                        style={{ fontSize: theme.fontSizes.small }}
                        multiple={true}
                        options={satRequirementOptions}
                        onChange={(value) =>
                            setTemporaryFilter({ ...temporaryFilter, satRequirement: value as string[] })
                        }
                    />
                </MyFormItem>
                <MyDivider />
                <MyFormItem label={"عدد التوصيات"}>
                    <Slider
                        value={temporaryFilter.numberOfRecommendationLetters}
                        range={true}
                        marks={lorMarks}
                        min={0}
                        max={3}
                        onChange={(value) =>
                            setTemporaryFilter({ ...temporaryFilter, numberOfRecommendationLetters: value as [number, number] })
                        }
                    />
                </MyFormItem>
                <MyDivider />
                {/* <MyFormItem label={"اخر موعد التقديم"} onClick={() => setDeadlineCalendarVisible(true)}>
                    <CalendarPicker
                        value={temporaryFilter.deadline}
                        max={new Date(2025, 12, 31)}
                        visible={deadlineCalendarVisible}
                        selectionMode="range"
                        onClose={() => setDeadlineCalendarVisible(false)}
                        onMaskClick={() => setDeadlineCalendarVisible(false)}
                        onChange={(value) =>
                            setTemporaryFilter({ ...temporaryFilter, deadline: value as [Date, Date] })
                        }
                    />
                </MyFormItem> */}
                <MyFormItem label={"رسوم التقديم"}>
                    <Slider
                        range={true}
                        popover={true}
                        min={0}
                        max={200}
                        value={temporaryFilter.applicationFee}
                        onChange={(value) =>
                            setTemporaryFilter({ ...temporaryFilter, applicationFee: value as [number, number] })
                        }
                    />
                </MyFormItem>
            </MyForm>
        </CenterPopup>
    );
}


export default UniversityProfileFilterModal;