import React, { createContext, useContext, ReactNode } from 'react';
import { PaginatedResponse, useMyGeneralQuery, useMyGeneralQueryAsInfiniteQuery } from '../api';
import { UniversityProfile } from '../university-profile/UniversityProfile';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';

// Define the context value type


// Create the context
const UniversityProfilesContext = createContext<UseInfiniteQueryResult<PaginatedResponse<UniversityProfile>, unknown>| undefined>(undefined);

// Define the provider component
export const UniversityProfilesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Fetch the data using the custom hook
  const query = useMyGeneralQueryAsInfiniteQuery<UniversityProfile, unknown>([`/university-profiles/`], `/university-profiles/`);


  return (
    <UniversityProfilesContext.Provider value={query}>
      {children}
    </UniversityProfilesContext.Provider>
  );
};

// Custom hook to use the context
export const useUniversityProfiles = () => {
  const context = useContext(UniversityProfilesContext);
  if (!context) {
    throw new Error('useUniversityProfiles must be used within a UniversityProfilesProvider');
  }
  return context;
};
