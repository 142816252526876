import React, {createContext, useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BASE_URL, randomColor } from '../App';
import { css } from '@emotion/react';
// import { Flex, MyInputGroup, MyInputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react';
import { FaCalendarDays, FaFaceGrin, FaFaceMeh, FaFaceSadTear, FaGraduationCap, FaRegFaceGrin, FaRegFaceMeh, FaRegFaceSadTear, FaRegNoteSticky } from "react-icons/fa6";
import {FaUniversity} from "react-icons/fa";
import { GiDiploma } from "react-icons/gi";
import { GREIcon, IELTSIcon } from '../Icons';
import {BsDoorClosed ,BsDoorClosedFill } from "react-icons/bs";



// import Picker from 'react-mobile-picker'
import { CgPerformance } from "react-icons/cg";
import LoremIpsum, { name } from 'react-lorem-ipsum';

import { Button, CalendarPicker, Card, Divider, Form, List, Picker, Stepper, Switch, TextArea, Radio, Space, CascadePicker, Modal, Popover, Toast } from 'antd-mobile'
import { PickerColumn, PickerValue } from 'antd-mobile/es/components/picker-view';
import { MyButton, MyCard, MyForm, MyFormItem, MyInput, MyNavbar, MySpace } from '../MyDesign';
import { Action } from 'antd-mobile/es/components/popover';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useMyGeneralMutation, useMyGeneralQuery } from '../api';
import { useUniversityUpdates } from '../contexts/UniversityUpdatesContext';
import { UniversityUpdate } from './UniversityUpdates';
import logger from '../logger';
import { useTheme } from '../contexts/ThemeContext';





type UniversityUpdateSubmission = {
  id?: string;
  user_id?: string;
  university?: string;
  general_degree?: string;
  specific_degree?: string;
  program?: string;
  admission_year?: string;
  admission_season?: string;
  status?: string;
  update_note?: string;
  gpa_scale?: string;
  gpa?: string;
  gre_quant?: string;
  gre_verbal?: string;
  gre_writing?: string;
  ielts?: string;
};




type UniversityUpdateSubmissionContextType = {
  universityUpdateSubmission: UniversityUpdateSubmission;
  setUniversityUpdateSubmission: React.Dispatch<React.SetStateAction<UniversityUpdateSubmission>>;
};
const UniversityUpdateSubmissionContext  = createContext<UniversityUpdateSubmissionContextType | undefined>(undefined);

const useUniversityUpdateSubmissionContext = () => {
  const universityUpdateSubmissionContext = useContext(UniversityUpdateSubmissionContext);
  if (!universityUpdateSubmissionContext) {
    throw new Error(
      "useCurrentUser has to be used within <CurrentUserContext.Provider>"
    );
  }
  return universityUpdateSubmissionContext;
};


// -------------------------Program---------------------
const actions: Action[] = [
  // { text: 'University of Minnesota - Twin Cities Campus' },
  // { text: 'Harvard University' },
  // { text: 'University of Chicago' },
]

const FormItemWithPopoveroptions = ({popoverOptionsEndpoint, field, placeholder, message, defaultValue} :
   {popoverOptionsEndpoint: string, field: string, placeholder: string, message: string, defaultValue?: string}) => {
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()
  const [visible, setVisible] = useState(false)

  const [actions, setActions] = useState<Action[]>([])

  const [searchText, setSearchText] = useState<string | undefined>(defaultValue);

  useMyGeneralQuery<string[]>(
    [popoverOptionsEndpoint, searchText], 
    popoverOptionsEndpoint, 
    { search: searchText},
    {onSuccess: options => {
      const newActions : Action[]  = options? options.map( option => { return {text: option} }) : []
      setActions(newActions)
    }})

  const handleTextInput = (text: string) => {
    setSearchText(text)
    setVisible(text.trim() !== '') // if the user is typing at least 1 letter, we show suggestions
  } 

  const handlePulloverClick = (node: Action) => {
    setSearchText(node.text?.toString())
    setUniversityUpdateSubmission({...universityUpdateSubmission, [field]: node.text?.toString()})
    setVisible(false)
  }
  
  return( 
    <MyFormItem name={placeholder} label={<FaUniversity />} rules={[{ required: true, message: message, 
      validator: () => { return searchText ? Promise.resolve() : Promise.reject(new Error(message))}  }]}
    >
      <Popover.Menu
          mode='dark'
          actions={actions}
          placement='top'
          trigger='click'
          visible={visible}
          onAction={handlePulloverClick}
        > 
        <MyInput 
          defaultValue={searchText}
          value={searchText}
          placeholder={placeholder}
          onChange={handleTextInput} 
          />
      </Popover.Menu>
    </MyFormItem>
  )
}

const UniversityInput = () => {
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()

  return (
    <FormItemWithPopoveroptions 
      defaultValue={universityUpdateSubmission.university}
      field='university' placeholder='الجامعة' message='اختر جامعة من الخيارات'
        popoverOptionsEndpoint="/university-update-submission/search-universities" />
  )
}


const ProgramInput = () => {
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()

  return (
    <FormItemWithPopoveroptions 
    defaultValue={universityUpdateSubmission.program}
    field='program' placeholder='البرنامج' message='اختر برنامج من الخيارات'
      popoverOptionsEndpoint="/university-update-submission/search-programs" />
  )
}



const degreeOptions = [
  {
    label: 'شهادة جامعية متوسطة',
    value: 'شهادة جامعية متوسطة',
    children: [
      { label: 'AA', value: 'شهادة جامعية متوسطة في الآداب (AA)' },
      { label: 'AS', value: 'شهادة جامعية متوسطة في العلوم (AS)' },
      { label: 'AAS', value: 'شهادة جامعية متوسطة في العلوم التطبيقية (AAS)' }
    ]
  },
  {
    label: 'بكالوريوس',
    value: 'بكالوريوس',
    children: [
      { label: 'BA', value: 'بكالوريوس في الآداب (BA)' },
      { label: 'BS', value: 'بكالوريوس في العلوم (BS)' },
      { label: 'BAA', value: 'بكالوريوس في الآداب التطبيقية (BAA)' },
      { label: 'BAS', value: 'بكالوريوس في العلوم التطبيقية (BAS)' },
      { label: 'B.Arch.', value: 'بكالوريوس في العمارة (B.Arch.)' },
      { label: 'BBA', value: 'بكالوريوس في إدارة الأعمال (BBA)' },
      { label: 'BFA', value: 'بكالوريوس في الفنون الجميلة (BFA)' },
      { label: 'BSN', value: 'بكالوريوس في العلوم التمريضية (BSN)' }
    ]
  },
  {
    label: 'ماجستير',
    value: 'ماجستير',
    children: [
      { label: 'MA', value: 'ماجستير في الآداب (MA)' },
      { label: 'MS', value: 'ماجستير في العلوم (MS)' },
      { label: 'MBA', value: 'ماجستير في إدارة الأعمال (MBA)' },
      { label: 'M.Ed.', value: 'ماجستير في التربية (M.Ed.)' },
      { label: 'MFA', value: 'ماجستير في الفنون الجميلة (MFA)' },
      { label: 'LL.M.', value: 'ماجستير في القوانين (LL.M.)' },
      { label: 'MPA', value: 'ماجستير في الإدارة العامة (MPA)' },
      { label: 'MPH', value: 'ماجستير في الصحة العامة (MPH)' },
      { label: 'MPP', value: 'ماجستير في السياسة العامة (MPP)' },
      { label: 'MSN', value: 'ماجستير في العلوم التمريضية (MSN)' },
      { label: 'MSW', value: 'ماجستير في الخدمة الاجتماعية (MSW)' }
    ],
  },
  {
    label:'دكتوراه',
    value: 'دكتوراه',
    children: [
      { label: 'Ph.D.', value: 'دكتوراه في الفلسفة (Ph.D.)' },
      { label: 'DBA', value: 'دكتوراه في إدارة الأعمال (DBA)' },
      { label: 'Ed.D.', value: 'دكتوراه في التربية (Ed.D.)' },
      { label: 'MD', value: 'دكتوراه في الطب (MD)' },
      { label: 'DNP', value: 'دكتوراه في ممارسة التمريض (DNP)' },
      { label: 'Pharm.D.', value: 'دكتوراه في الصيدلة (Pharm.D.)' },
      { label: 'Psy.D.', value: 'دكتوراه في علم النفس (Psy.D.)' },
      { label: 'JD', value: 'دكتوراه في القانون (JD)' }
    ]
  }
]

function DegreeInput() {
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()
  const [visible, setVisible] = useState(false)

  return (
    <MyFormItem  label={<GiDiploma/>} name={'degree'} rules={[{ required: true, message: 'اختر الدرجة العلمية',
      validator: () => { return universityUpdateSubmission.general_degree ? Promise.resolve() : Promise.reject(new Error('اختر الدرجة العلمية'))}
  }]}
      onClick={() => setVisible(true)}>     
        {universityUpdateSubmission.specific_degree ? 
          <div>{ universityUpdateSubmission.specific_degree }</div> : 
          <div style={{color: '#4d4d4d'}}> {"الدرجة العلمية"} </div>}
 
      <CascadePicker
        title={"الدرجة العلمية"}
        options={degreeOptions}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        onConfirm={(value, extend) => {
          setUniversityUpdateSubmission(
            {...universityUpdateSubmission, general_degree: value[0] as string, specific_degree: value[1] as string})
        }}
        onSelect={val => {
        }}
      />
    </MyFormItem>
  )
}


const AdmissionSeasonInput = () => {
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()
  const [visible, setVisible] = useState(false)


  return(
    <MyFormItem name="عام القبول" label={<FaCalendarDays />} rules={[{ required: true, message: 'اختر عام القبول',
    validator: () => { return universityUpdateSubmission.admission_season ? Promise.resolve() : Promise.reject(new Error('اختر عام القبول'))}
  }]}
      onClick={() => setVisible(true)}> 
      {universityUpdateSubmission.admission_season ? 
        <div>{ universityUpdateSubmission.admission_season + " " + universityUpdateSubmission.admission_year }</div> : 
        <div style={{color: ' #4d4d4d'}}> {"عام القبول"} </div>}
      <Picker
        columns={[['الخريف', 'الربيع', 'الصيف'], ['2024', '2025']]}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        value={[universityUpdateSubmission.admission_season as PickerValue , universityUpdateSubmission.admission_year as PickerValue]}
        onConfirm={value => {
          setUniversityUpdateSubmission({...universityUpdateSubmission, admission_season: value[0] as string, admission_year: value[1] as string})  
        }}
      />
    </MyFormItem>
  )
}


const ProgramInputGroup = () => {
  return (
    <MyCard>
      <UniversityInput />
      <Divider direction='horizontal' style={{margin: '0px'}}/>
      <ProgramInput />
      <Divider direction='horizontal' style={{margin: '0px'}}/>
      <DegreeInput />
      <Divider direction='horizontal' style={{margin: '0px'}}/>
      <AdmissionSeasonInput/>
    </MyCard>
  )
}



// -------------------------Status---------------------

const AdmissionStatusInput = () => {
  const {theme} = useTheme()
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()

  return(
    <MyFormItem name={"الحالة"} label={"الحالة"} rules={[{ required: true, message: 'اختر حالة القبول', 
      validator: () => { return universityUpdateSubmission.status ? Promise.resolve() : Promise.reject(new Error('اختر حالة القبول'))}

    }]}>
        <Radio.Group
            defaultValue={universityUpdateSubmission.status}
            value={universityUpdateSubmission.status}
            onChange={val => {
              setUniversityUpdateSubmission({...universityUpdateSubmission, status: val as string})  
            }}
          >
            <Space direction='vertical'>
              <Radio
                value={"تحت المراجعة"}
                icon={checked => checked ? ( <FaFaceMeh />) : ( <FaRegFaceMeh color={theme.colors.textDisabled} /> )}
              >
                {"تحت المراجعة"}
              </Radio>
              <Radio
                value={"مقبول"}
                icon={checked => checked ? ( <FaFaceGrin color={theme.colors.success} />) : ( <FaRegFaceGrin color={theme.colors.textDisabled} /> )}
              >
                {"مقبول"}
              </Radio>
              <Radio
                value={"مرفوض"}
                icon={checked => checked ? ( <FaFaceSadTear color={theme.colors.error} />) : (<FaRegFaceSadTear color={theme.colors.textDisabled} />)}
              >
                {"مرفوض"}
              </Radio>
              <Radio
                value="مسحوب"
                icon={checked => checked ? ( <BsDoorClosedFill  color={theme.colors.warning}  />) : ( <BsDoorClosed color={theme.colors.textDisabled} />)}
              >
                {"مسحوب"}
              </Radio>
            </Space>
        </Radio.Group>
      </MyFormItem>
  )
}



const AdmissionNoteInput = () => {
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()
  return(
    <MyFormItem  label={<FaRegNoteSticky /> }>
      <TextArea 
        defaultValue={universityUpdateSubmission.update_note ?? ""}
        placeholder={"أكتب أي ملاحطة عن عملية التقديم على القبول. كن مختصرا."} autoSize={{ minRows: 3, maxRows: 7 }} 
        onChange={ text => 
          setUniversityUpdateSubmission({...universityUpdateSubmission, update_note: text})  
        }
        maxLength={250} showCount={true}/>
    </MyFormItem>
  )
}

const StatusInputGroup = () => {
  return(
    <MyCard  >
      <AdmissionStatusInput />
      <Divider direction='horizontal' style={{margin: '0px'}}/>
      <AdmissionNoteInput />
    </MyCard>
  )
}


// --------------------------- Stats --------------------------------


const gpaScales = [
  'مقياس 4.0 (الولايات المتحدة)',
  'مقياس 5 نقاط (المملكة العربية السعودية)',
  'مقياس 100 نقطة (دولي)',
  'مقياس النسبة المئوية (الهند وبعض الدول الأخرى)',
  'مقياس 20 نقطة (المملكة المتحدة)',
  'مقياس 7 نقاط (أستراليا)',
  'مقياس 10 نقاط (كندا)'
];


const GPAInput = () =>{
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()
  const [visible, setVisible] = useState(false)
  


  return(
    <>
      <MyFormItem name={'ميزان المعدل التراكمي"'} label={"ميزان المعدل التراكمي"} rules={[{ required: true, message: 'اختر ميزان المعدل التراكمي',
        validator: () => { return universityUpdateSubmission.gpa_scale ? Promise.resolve() : Promise.reject(new Error('اختر ميزان المعدل التراكمي'))}
        }]}
        onClick={() => setVisible(true)}>
        {universityUpdateSubmission.gpa_scale ? 
          <div>{ universityUpdateSubmission.gpa_scale }</div> : 
          <div style={{color: ' #4d4d4d'}}> {"ميزان المعدل التراكمي"} </div>}
        <Picker
          columns={[gpaScales]}
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
          value={[universityUpdateSubmission.gpa_scale as PickerValue]}
          onConfirm={value => {
            setUniversityUpdateSubmission({...universityUpdateSubmission, gpa_scale: value[0] as string})
          }}
        />
      </MyFormItem>
      <MyFormItem name='المعدل التراكمي'  label={"المعدل التراكمي"} rules={[{ required: true, message: 'ادخل لمعدل التراكمي',
          validator: () => { return universityUpdateSubmission.gpa ? Promise.resolve() : Promise.reject(new Error('ادخل المعدل التراكمي'))}
        }]}
      >
        <MyInput 
          defaultValue={universityUpdateSubmission.gpa}
          onChange={ (value) => setUniversityUpdateSubmission({...universityUpdateSubmission, gpa: value})}
          placeholder={"المعدل التراكمي"} />
      </MyFormItem>
    </>
  )
}
const greScores = {
  verbalReasoning: Array.from({ length: 41 }, (_, index) => (index + 130).toString() ), // 130 to 170
  quantitativeReasoning: Array.from({ length: 41 }, (_, index) => (index + 130).toString()), // 130 to 170
  analyticalWriting: Array.from({ length: 13 }, (_, index) => (index * 0.5).toString()) // 0 to 6 in half-point increments
};

const GREInput = () =>{
  const [quantVisible, setQuantVisible] = useState(false)
  const [verbalVisible, setVerbalVisible] = useState(false)
  const [writingVisible, setWritingVisible] = useState(false)


  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()

  return (
    <MyFormItem label={<GREIcon style={{height: '40px', width: '50px'}} />} >
      <MyFormItem label={"كمي"}  onClick={() => setQuantVisible(true)}>
        {universityUpdateSubmission.gre_quant ? 
          <div>{universityUpdateSubmission.gre_quant.toString()}</div> : 
          <div style={{color: ' #4d4d4d'}}> {"كمي"} </div>}
        <Picker         
          columns={[greScores.quantitativeReasoning]}
          visible={quantVisible}
          onClose={() => {
            setQuantVisible(false)
          }}
          value={[ universityUpdateSubmission.gre_quant as PickerValue ]}
          onConfirm={value => {
            setUniversityUpdateSubmission({ ...universityUpdateSubmission, gre_quant: value[0] as string})
          }}
        />
      </MyFormItem>

      <MyFormItem label={"لفظي"} onClick={() => setVerbalVisible(true)}>
        {universityUpdateSubmission.gre_verbal ? 
          <div>{universityUpdateSubmission.gre_verbal.toString()}</div> : 
          <div style={{color: ' #4d4d4d'}}> {"لفظي"} </div>}
        <Picker         
          columns={[greScores.verbalReasoning]}
          visible={verbalVisible}
          onClose={() => {
            setVerbalVisible(false)
          }}
          value={[ universityUpdateSubmission.gre_verbal as PickerValue ]}
          onConfirm={value => {
            setUniversityUpdateSubmission({ ...universityUpdateSubmission, gre_verbal: value[0] as string})
          }}
        />
      </MyFormItem>

      <MyFormItem label={"كتابي"} onClick={() => setWritingVisible(true)}>
        {universityUpdateSubmission.gre_writing ? 
          <div>{universityUpdateSubmission.gre_writing.toString()}</div> : 
          <div style={{color: ' #4d4d4d'}}> {"كتابي"} </div>}
        <Picker         
          columns={[greScores.analyticalWriting]}
          visible={writingVisible}
          onClose={() => {
            setWritingVisible(false)
          }}
          value={[universityUpdateSubmission.gre_writing as PickerValue] }
          onConfirm={value => {
            setUniversityUpdateSubmission({ ...universityUpdateSubmission, gre_writing: value[0] as string})
          }}
        />
      </MyFormItem>
    </MyFormItem>
  )
}
const ieltsScores = ['0', '0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9'];

const IELTSInput = () =>{
  const {universityUpdateSubmission, setUniversityUpdateSubmission} = useUniversityUpdateSubmissionContext()
  const [visible, setVisible] = useState(false)


  return (
    <MyFormItem  label={<IELTSIcon style={{height: '40px', width: '50px'}}  />}  onClick={() => setVisible(true)}>
       {universityUpdateSubmission.ielts ? 
        <div>{universityUpdateSubmission.ielts}</div> : 
        <div style={{color: ' #4d4d4d'}}> {"درجة الايلتس"} </div>}
       <Picker
          columns={[ieltsScores]}
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
          value={[universityUpdateSubmission.ielts as PickerValue]}
          onConfirm={value => {
            setUniversityUpdateSubmission({...universityUpdateSubmission, ielts: value[0] as string})
          }}
        />
    </MyFormItem>
  )
}

const StatInputGroup = () => {
    return(
      <MyCard >
          <GPAInput />
          <Divider direction='horizontal' style={{margin: '0px'}}/>
          <GREInput />
          <Divider direction='horizontal' style={{margin: '0px'}}/>
          <IELTSInput />
      </MyCard>
    )
}




const UniversityUpdateSubmissionScreen = () => {
  const {theme} = useTheme()
  const { universityUpdateId } = useParams<{ universityUpdateId?: string }>();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useUniversityUpdates();
  const universityUpdates  = data?.pages.flatMap((page) => page.data) || [];
  
  const specificUpdate = universityUpdates.find(update => update.id === universityUpdateId);

  
 const univeristyUpdateSumissionInitial =  specificUpdate ? {
      id: specificUpdate.id,
      user_id: specificUpdate.user_id,
      university: specificUpdate.university,
      general_degree: specificUpdate.general_degree,
      specific_degree: specificUpdate.specific_degree,
      program: specificUpdate.program,
      admission_year: specificUpdate.admission_year,
      admission_season: specificUpdate.admission_season,
      status: specificUpdate.status,
      update_note: specificUpdate.update_note,
      gpa_scale: specificUpdate.gpa_scale,
      gpa: specificUpdate.gpa,
      gre_quant: specificUpdate.gre_quant,
      gre_verbal: specificUpdate.gre_verbal,
      gre_writing: specificUpdate.gre_writing,
      ielts: specificUpdate.ielts
    } : {} as UniversityUpdateSubmission
  

  const [universityUpdateSubmission, setUniversityUpdateSubmission] = useState<UniversityUpdateSubmission>(univeristyUpdateSumissionInitial)


  

  const mutation = specificUpdate ? useMyGeneralMutation(`/university-updates/${specificUpdate.id}`, 'put') : useMyGeneralMutation("/university-updates", 'post')
  const navigate = useNavigate()

  const onSubmit = async () => {
    try {

      await mutation.mutateAsync(universityUpdateSubmission);
      navigate('/university-update', {replace: true})
    } catch (error) {
      logger.error('Error during post operation:', error);
    }
  };

  return (
      <UniversityUpdateSubmissionContext.Provider value={{universityUpdateSubmission, setUniversityUpdateSubmission}}>
        <MyNavbar backArrow={true} leftTitle='ارفع نتيجة جامعة'  />
        <MyForm
          form={Form.useForm()[0]}
          
          layout='horizontal'
          onFinish={onSubmit}

          footer={
            <div style={{flexDirection: 'row', display: 'flex', gap: 10}}>
            <MyButton  block type='button' disabled={true} style={{backgroundColor: theme.colors.error, color: theme.colors.background}} size='large' >
                {"احذف"}
            </MyButton>
            <MyButton  block type='submit'  style={{backgroundColor: theme.colors.primary, color: theme.colors.background}} size='large' >
              {"تم"}
            </MyButton>
            </div>
          }
        >   
          <MySpace direction='vertical'>
            <ProgramInputGroup />
            <StatusInputGroup />
            <StatInputGroup />
          </MySpace>
        </MyForm>
      </UniversityUpdateSubmissionContext.Provider>
  )
}

export {UniversityUpdateSubmissionScreen}










