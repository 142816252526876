import { List } from "antd-mobile";
import { MyNavbar } from "../MyDesign";
import { FaGraduationCap, FaSackDollar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";






const ServicesList = () => {
    const navigate = useNavigate()
    return(
        <List style={{direction: 'ltr'}}>
            <List.Item prefix={<FaGraduationCap />} onClick={() => {navigate('university-admission-service')}}>
              احصل على قبول  جامعي 
            </List.Item>
            <List.Item prefix={<FaSackDollar />} onClick={() => {navigate('financial-service')}}>
            احصل على معونة مالية
            </List.Item>
            
      </List>

    )
}




const ServicesScreen = () => {
    return(
        <>
            <MyNavbar  leftTitle={"خدمات"} />
            <ServicesList />
        </>
    )
}


export default ServicesScreen;