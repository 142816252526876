// src/LoginForm.tsx
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { MyButton, MyCard, MySpace } from '../MyDesign';
import Rodal from 'rodal';
import { Button, CenterPopup, Mask } from 'antd-mobile';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { FcGoogle } from "react-icons/fc";
import logger from '../logger';
import logo from '../assets/ibtaath_cafe_logo.png'
import { useTheme } from '../contexts/ThemeContext';
import ReactGA from 'react-ga4';
import { useFirebaseAuth } from '../contexts/FirebaseAuthContext';
import { UserCredential } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { set } from 'date-fns';


export const LoginButton = () => {
  const { loginWithGoogle, currentUser } = useAuth();

  const handleGoogleSignIn = async () => {
    try {
      const {user} = await loginWithGoogle() as UserCredential;
      ReactGA.event('user_login', {
        event_category: 'User',
        event_label: 'Login',
        user_id: user.uid,
        user_name: user.displayName 
      });
    } catch (error) {
      logger.error("Error signing in with Google:", error);
    }
  };

  return (
    <MyButton style={{ height: '50px' }} onClick={handleGoogleSignIn}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FcGoogle />
              <div>سجل الدخول مع قوقل</div>
            </div>
    </MyButton> )
}

const LoginScreen = () => {
  const [visible, setVisible] = React.useState(true);
  const navigate = useNavigate();

  const {theme} = useTheme()
  
  return (
    
        <CenterPopup
            visible={visible}
            disableBodyScroll
            onMaskClick={() => setVisible(false)}
            maskStyle={{ backdropFilter: "blur(8px)" }}
            bodyStyle={{
              padding: '40px',
                height: "60vh",
                borderRadius: "24px",
                // overflow: "scroll",
                boxShadow: theme.shadows.surfaceSecondary,
                backgroundColor: theme.colors.surface,
                scrollbarWidth: "none",
                
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
            }}
        >
          <img
            src={logo}
            alt="Logo"
            style={{ width: '100px', height: '100px' }}
          />
          <LoginButton />
        </CenterPopup>

  )
};

export default LoginScreen;
