
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { FirebaseAuthProvider, useFirebaseAuth, useFirebaseAuthMock } from './FirebaseAuthContext';

const AUTH_MODE = process.env.REACT_APP_AUTH_MODE as string;

export const useAuth = AUTH_MODE === 'mock' ? useFirebaseAuthMock : useFirebaseAuth ;


const EmptyAuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    return(
        <>{children}</>
    )
};


export const AuthProvider = AUTH_MODE === 'mock' ? EmptyAuthProvider : FirebaseAuthProvider ;