
import React, { useEffect } from 'react';
import { UniversityUpdatesScreen } from './univeristy-updates/UniversityUpdates';

import { QueryClient, QueryClientProvider } from 'react-query';
import { UniversityUpdateSubmissionScreen } from './univeristy-updates/UniversityUpdateSubmission';
import { Button, ConfigProvider } from 'antd-mobile';

import enUS from 'antd-mobile/es/locales/en-US';
import  FirebaseLoginScreen from './profile/Login';

import {useFirebaseAuth } from './contexts/FirebaseAuthContext';
import logger from './logger';

import { darkTheme } from './theme';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { RouterProvider } from 'react-router-dom';
import Router from './Router';
import { UserProvider } from './contexts/UserContext';
import { UniversityUpdatesProvider } from './contexts/UniversityUpdatesContext';

import ReactGA from 'react-ga4';

var randomColor = require('randomcolor'); // import the script

const BASE_URL = process.env.REACT_APP_API_URL as string;

const queryClient = new QueryClient();





function App() {
  useEffect(() => {
    document.documentElement.style.backgroundColor = 'black';
  }, []);

  useEffect(() => {
    ReactGA.initialize('G-SZRJQRD0H8')
    
  }, []);

  


  return (
    <AuthProvider>
      <ConfigProvider locale={enUS} >
        <ThemeProvider>
          <QueryClientProvider client={queryClient} >
              <Router />   
          </QueryClientProvider>
        </ThemeProvider>
      </ConfigProvider>
    </AuthProvider>
  );
}

export {BASE_URL, randomColor}
export default App;
