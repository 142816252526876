import { CascadePicker, CenterPopup, Divider, Form, TextArea } from "antd-mobile";
import { GiDiploma } from "react-icons/gi";
import { useState } from "react";
import { FaRegNoteSticky } from "react-icons/fa6";
import SuccessAnimation from '../assets/SuccesAnimation.json'
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { MyButton } from "../MyDesign";
import { useTheme } from "../contexts/ThemeContext";

interface SubmissionSuccessModalProps extends React.ComponentProps<typeof CenterPopup> {
    message?: string;
    onOk?: () => void;
  }
  

const SubmissionSuccessModal : React.FC<SubmissionSuccessModalProps> = ({visible, setVisible, message, onOk, bodyStyle, maskStyle, ...restProps } : any) => {
    const {theme} = useTheme();
    return (
        <CenterPopup
            visible={visible}
            disableBodyScroll
            // onMaskClick={() => setVisible(false)}
            maskStyle={{ backdropFilter: "blur(8px)", ...maskStyle }}
            bodyStyle={{
            padding: '40px',
                height: "40vh",
                borderRadius: "24px",
                // overflow: "scroll",
                boxShadow: theme.shadows.surfaceSecondary,
                backgroundColor: theme.colors.surface,
                scrollbarWidth: "none",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                ...bodyStyle,
            }} {...restProps}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10}}>
                    <Lottie animationData={SuccessAnimation} style={{maxWidth: '100px'}} />
                    {/* <h1 style={{textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold'}}>احصل على قبول في الجامعات العالمية</h1> */}
                    <p style={{textAlign: 'center', fontSize: '1rem', maxWidth: '300px'}}>{message ?? "شكرا لك"}</p>
            </div>
            <MyButton 
                onClick={onOk ? onOk : () => { setVisible(false) }}
                >
                {"تم"}
            </MyButton>
            
    </CenterPopup>
    )
}

export default SubmissionSuccessModal;