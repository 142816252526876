import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    Routes,
    GetScrollRestorationKeyFunction,
    ScrollRestoration,
    useLocation,
    useNavigate,
    RouteProps,
  } from "react-router-dom";
import { UniversityUpdatesScreen } from "./univeristy-updates/UniversityUpdates";
import { useFirebaseAuth } from "./contexts/FirebaseAuthContext";
import { FaGraduationCap, FaEllipsisVertical, FaSistrix, FaFilter, FaSort, FaArrowsSpin, FaListUl } from "react-icons/fa6";
import {
    AppOutline,
    MessageOutline,
    UnorderedListOutline,
    UserOutline,
    SearchOutline,
    MoreOutline
  } from 'antd-mobile-icons'
import  FirebaseLoginScreen from './profile/Login';
import { FC, useEffect, useState } from "react";
import { useTheme } from "./contexts/ThemeContext";
import { TabBar } from "antd-mobile";
import { ProfileScreen } from "./profile/Profile";
import { SettingsScreen } from "./profile/Settings";
import logger from "./logger";
import { UniversityUpdateSearchScreen } from "./univeristy-updates/UniversityUpdateSearch";
import { UniversityUpdateSubmissionScreen } from "./univeristy-updates/UniversityUpdateSubmission";
import { UniversityProfileScreen } from "./university-profile/UniversityProfile";
import { UniversityProfilesScreen } from "./university-profile/UniversityProfiles";
import { UserProvider } from "./contexts/UserContext";
import { UniversityUpdatesProvider } from "./contexts/UniversityUpdatesContext";
import UniversityProfilesSearchScreen from "./university-profile/UniversityProfilesSearch";
import UniversityProfileFilterScreen from "./university-profile/UniversityProfileFilter";
import { UniversityProfilesProvider, useUniversityProfiles } from "./contexts/UniveristyProfilesContext";
import { create } from "domain";
import WelcomeModal from "./Welcome";
import ServicesScreen from "./services/Services";
import UniversityAdmissionServiceScreen from "./services/university-admission-service/UniversityAdmissionService";
import UniversityAdmissionServiceFormScreen from "./services/university-admission-service/UniversityAdmissionServiceForm";
import FinancialServiceScreen from "./services/financial-service/FinancialService";
import FinancialServiceFormScreen from "./services/financial-service/FinancialServiceForm";


const MyBottomTabBar: FC = () => {
    const location = useLocation()
    const { pathname } = location
    const mainRoute = pathname.split('/')[1];
    const navigate = useNavigate()
    const {theme} = useTheme()
  
    // const [activeKey, setActiveKey] = useState('university-update')
  
    const tabs = [
      
      {
        key: 'profile',
        title: 'الحساب',
        icon: <UserOutline />,
      },
      {
        key: 'services',
        title: 'الخدمات',
        icon: <FaListUl />
      },
      
      {
        key: 'university-update',
        title: 'تحديثات القبول',
        icon: <FaArrowsSpin />
      },
      {
        key: 'university-profiles',
        title: 'الجامعات',
        icon: <FaGraduationCap />
      }
      
      
    ]
    return (
        <TabBar
          activeKey={mainRoute}
          style={{position: 'sticky',  height: "50px",  bottom: 0, width: "100%", 
            color: 'red',   
            backgroundColor: theme.colors.background,
            zIndex: 2 }}
          onChange={ (key) => { navigate(key, {relative: "path"}); }}
          >
          {tabs.map(item => {
            return(
              <TabBar.Item key={item.key} icon={item.icon} title={item.title}   />
          )})}
        </TabBar>
    )
  }
  


const ParentLayout = () => {
  return (
    <div style={{direction: 'rtl', paddingLeft: "10px", paddingRight: "10px", height: '100vh', width: '100hv',  display: 'flex', justifyContent: 'center'}} >
      <div style={{maxWidth: '700px', width: '100%'}}> 
        <div style={{ minHeight: '100%',}}>
          <Outlet  />
          <WelcomeModal />
        </div>
        <MyBottomTabBar />
      </div>   
      <ScrollRestoration getKey={(location, matches) => {
          return location.pathname } }/>
    </div>
  );
};








    
const universityProfileRoute = 
  <Route path='university-profile' >
    <Route index element={ <UniversityProfileScreen />} />
    <Route path=':universityNameAr' element={<UniversityProfileScreen />}/>
  </Route>


type ProtectedScreenProps = {
  // Add your custom properties here
  children: React.ReactNode; 
  currentUser: any;
};





const ProtectedScreen: React.FC<ProtectedScreenProps> = ({ currentUser, children }) => {
  if (currentUser === null) {

    // Redirect to the sign-in page if the user is not authenticated
    return <FirebaseLoginScreen />
  }
  logger.debug(currentUser)



  // If the user is authenticated, render the child component
  return children;
};




const getRoutes = (currentUser: any) => {
  return createRoutesFromElements(
      <Route element={<ParentLayout />}> 
          <Route path="signin" element={<FirebaseLoginScreen />} />
          <Route path='profile'  >
              <Route index element={ 
                // <ProtectedScreen currentUser={currentUser} >
                  <ProfileScreen />
                // </ProtectedScreen>
              }  />
              <Route path='settings' element={<SettingsScreen />} />
              {universityProfileRoute}
          </Route>
          <Route path='services'  >
              <Route index element={ 
                // <ProtectedScreen currentUser={currentUser} >
                  <ServicesScreen />
                // </ProtectedScreen>
              }  />
              
              <Route path='university-admission-service'>
                <Route index element={<UniversityAdmissionServiceScreen />} />
                <Route path='form' element={
                  <ProtectedScreen currentUser={currentUser} >
                    <UniversityAdmissionServiceFormScreen />
                  </ProtectedScreen>
                  } />
              </Route>
              <Route path='financial-service'>
                <Route index element={<FinancialServiceScreen />} />

                <Route path='form' element={
                    <ProtectedScreen currentUser={currentUser} >
                      <FinancialServiceFormScreen />
                    </ProtectedScreen>
                    } />
              </Route>
          </Route>
          <Route path='university-update'  >
              <Route  index element={ <UniversityUpdatesScreen />} />
              <Route path='search' >
                <Route index element={
                  <ProtectedScreen currentUser={currentUser} >
                    <UniversityUpdateSearchScreen />
                  </ProtectedScreen>
              } />
                {universityProfileRoute}
              </Route>
              {universityProfileRoute}
              <Route path='submission/:universityUpdateId?' element={
                <ProtectedScreen currentUser={currentUser} >
                  <UniversityUpdateSubmissionScreen />
                </ProtectedScreen>
                }/>
          </Route>
          <Route path='university-profiles' >
            <Route index element={ <UniversityProfilesScreen  />}   />
            <Route path='search' >
                <Route index element={
                  <ProtectedScreen currentUser={currentUser} >
                    <UniversityProfilesSearchScreen />
                  </ProtectedScreen>
                } />
                {universityProfileRoute}
                {/* <Route path='filter' element={<UniversityProfileFilterScreen />}/> */}
            </Route>
            {universityProfileRoute}
          </Route>
          <Route path="*" element={<Navigate to="university-update" replace />} />
      </Route>
  )   
}


const NewRouter = ({currentUser} : any) => {
  const router = createBrowserRouter(getRoutes(currentUser));
  return (
    <UserProvider>
      <UniversityUpdatesProvider>
        <UniversityProfilesProvider>
          <RouterProvider router={router} />
        </UniversityProfilesProvider>
      </UniversityUpdatesProvider>
    </UserProvider>
  )
}


const Router = () => {
  const { currentUser, loading } = useFirebaseAuth();
  if (loading) {
    return <></>; // Show an empty fragment while loading
  }

  return <NewRouter currentUser={currentUser} /> 
};


  

  export default Router